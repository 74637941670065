import { Box } from "@mui/material";
import useTitle from "hooks/useTitle";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SmsIndex: FC = () => {
  const navigate = useNavigate();

  // change navbar title
  useTitle("SMS");

  useEffect(() => {
    navigate(`/dashboard/sms/send`);
  });

  return (
    <Box pt={2} pb={4}>
      
    </Box>
  );
};
export default SmsIndex;
