import { Box, Card, Divider, Grid, IconButton, styled, useTheme } from "@mui/material";
import { H4, H5, H6, Small, Tiny } from "components/Typography";
import UkoAvatar from "components/UkoAvatar";
import React, { FC } from "react";

// component props interface
interface CustomerCardProps {
  customer: any,
  onCardClick?: (obj: any) => void;
};

const CustomerCard: FC<CustomerCardProps> = (props) => {
  const { customer, onCardClick } = props;
  const theme = useTheme();
  return (
    <Card>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: 2,
        }}
      >
        <H4>{customer.full_name}</H4>
        <H6>{customer.mobile_number}</H6>
        <Tiny color="text.disabled" fontWeight={500}>
          County: {customer.region.county.name}
        </Tiny>
        <Tiny color="text.disabled" fontWeight={500}>
          Sub-County: {customer['Sub-County']}
        </Tiny>
        <Tiny color="text.disabled" fontWeight={500}>
          Village: {customer['Village']}
        </Tiny>
        <Tiny color="text.disabled" fontWeight={500}>
          Alternative No: {customer['Alternative Phone Number']}
        </Tiny>
      </Box>

      <Divider sx={{ my: 1, marginLeft: 10, marginRight: 10 }} />

      {(customer.account).map((_: any, index: any) => {
        return <Box sx={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          paddingLeft: 5,
          paddingRight: 5,
          paddingBottom: 2,
          textAlign: "left",
          "&:hover": { color: "primary.main", backgroundColor: "secondary.light" },
          cursor: onCardClick ? "pointer" : "unset",
        }} onClick={() => { onCardClick && onCardClick(_) }}>
          <Small>{_.payment_plan}</Small>
          <Small>Status: {_.account_status}</Small>
          <Small>#{_.account_no}</Small>
          <Small>Paid: {_.total_paid}/=</Small>
          <Small>Payoff: {_.payoffamount}/=</Small>
          {/* <Small>Next Pay: {moment(new Date(_?.next_payment_due_time).toLocaleString()).format("YYYY-MM-DD")}</Small>
            <Small>Last paid: {moment(new Date(_?.last_payment_datetime).toLocaleString()).format("YYYY-MM-DD")}</Small> */}
        </Box>
      })}
    </Card>
  );
};

export default CustomerCard;
