import {
  Box, Button, styled, Dialog,
  List, ListItem, ListItemText,
  AppBar, Toolbar, Typography, Slide,
  IconButton,
  Divider,
  CircularProgress
} from "@mui/material";
import { TransitionProps } from '@mui/material/transitions';

import FlexBox from "components/FlexBox";
import ColumnShape from "components/Forms/PreAftercare/ColumnShape";
import TableDesign from "components/Forms/PreAftercare/TableDesign";
import useTitle from "hooks/useTitle";
import React, { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { loggedInUser } from "utils/state";
import CloseIcon from "icons/CloseIcon";
import ObjectDialogView from "components/ObjectDialogView";
import { listSurveys } from "redux/customers";
import LightIconButton from "components/LightIconButton";
import PlusIcon from "icons/PlusIcon";
import ShareIcon from "icons/ShareIcon";

// styled component
const StyledFlexBox = styled(FlexBox)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: 20,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": { maxWidth: "100%" },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

const FormsAftercareInitialSurveysList: FC = () => {
  // change navbar title
  useTitle("Submissions");

  const user = loggedInUser();

  const navigate = useNavigate();
  const handleAddNewSubmission = () => navigate("/dashboard/forms/fo/after-care/initial-survey/new");

  const [state, setState] = useState('loading');
  const [open, setOpen] = useState(false);
  const [curViewRow, setCurViewRow] = useState(null);
  const [rows, setRows] = useState([]);

  const handleRowClicked = (row: any) => {
    setOpen(true);
    setCurViewRow(row);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
    setCurViewRow(null);
  };

  useEffect(() => {
    if (rows.length <= 0) {
      setState('loading');
      listSurveys({
        url: `v1/portfolio_health/pre_aftercare-survey/`,
        params: {
          limit: 200,
          created_by: user?.id,
        }
      }).then((response: any) => {
        if (response.status === 200) {
          setRows(response.data.results);
        } else {
          toast.error('Something went wrong.');
        }
        setState('idle');
      }).catch((error) => {
        toast.error(error);
        setState('idle');
      });
    }

  }, [rows, state]);

  const DialogInfo: FC = () => {

    const Transition = React.forwardRef(function Transition(
      props: TransitionProps & {
        children: React.ReactElement;
      },
      ref: React.Ref<unknown>,
    ) {
      return <Slide direction="up" ref={ref} {...props} />;
    });


    const objectValue = (obj: any, _: string) => {
      return (obj !== null) ? (typeof (obj[_]) === "object") ? (['partner', 'partner_branch'].includes(_)) ? obj[_]['name'] : '' : (obj !== null) ? obj[_] : '' : '';
    };

    const objectToLines = (obj: any) => {
      var lines = ``;
      if (obj !== null) {
        lines += `*Pre aftercare survey*`;
        lines += `\n\n`;
        Object.keys(curViewRow || []).map((_) => {
          lines += `*${_.replaceAll("_", " ").toUpperCase()}* - ${objectValue(curViewRow || null, _)}`;
          lines += `\n`;
        });
        lines += `*Posted by ${user?.email}*`;
      }
      return lines;
    };

    const handleShare = async () => {
      var message = objectToLines(curViewRow);
      if (navigator.share) {
        try {
          await navigator.share({
            title: `New installation request by ${user?.username}`,
            text: `${message}`,
          });
          toast.success('Shared successfully.');
        } catch (error) {
          toast.error(`Error sharing ${error}`);
        }
      } else {
        toast.error('Web Share API not supported');
        let url = `https://web.whatsapp.com/send?group=`;
        url += `&text=${encodeURI(message)}&app_absent=0`;
        window.open(url);
      }
    };

    return <Dialog
      open={open}
      onClose={handleCloseDrawer}
      TransitionComponent={Transition}
      fullScreen
    >
      <AppBar sx={{ position: 'relative', backgroundColor: "background.secondary" }}>
        <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleShare}
            aria-label="close"
          >
            <ShareIcon />
          </IconButton>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseDrawer}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {(curViewRow !== null || curViewRow !== undefined) ?
        <List>
          {Object.keys(curViewRow || []).map((_) => {
            return <ListItem alignItems="flex-start">
              <ListItemText
                primary={_.replaceAll("_", " ").toUpperCase()}
                secondary={
                  <React.Fragment>
                    <Typography variant="body2" color="text.primary" sx={{ fontWeight: 'bold' }}>
                      {objectValue(curViewRow || null, _)}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          })}

        </List>
        : <></>}
    </Dialog>

  };


  return (
    <Box pt={2} pb={4}>

      <StyledFlexBox>
        <LightIconButton onClick={handleAddNewSubmission} size="small">
          <PlusIcon fontSize="small" />
          Create
        </LightIconButton>
      </StyledFlexBox>
      {(state === "loading") ?
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress />
        </Box>
        :
        <TableDesign columnShape={ColumnShape} data={rows} rowClick={((rowData: object) => handleRowClicked(rowData))} />
      }
      {/* <ObjectDialogView obj={curViewRow} state={open} handleCloseDrawer={handleCloseDrawer} /> */}
      <DialogInfo />

    </Box>
  );
};

export default FormsAftercareInitialSurveysList;
