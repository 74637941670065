import { _com_hash } from '../../package.json';

const loggedInUser = () => {
    var user: any = localStorage.getItem(`${_com_hash}_user`);
    return JSON.parse(user);
};

const accessToken = () => {
    var token: any = localStorage.getItem(`${_com_hash}_accessToken`);
    return token;
};

export { loggedInUser, accessToken };
