import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Card, CircularProgress, Divider, Grid, styled, Tab } from "@mui/material";
import FlexBox from "components/FlexBox";
import { H6, Small } from "components/Typography";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import useTitle from "hooks/useTitle";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import SearchInput from "components/SearchInput";
import { searchCustomerAcrossCall } from "redux/customers";
import CustomerCard from "components/Customer/CustomerCard";
import LightIconButton from "components/LightIconButton";
import PlusIcon from "icons/PlusIcon";


// styled component
const StyledFlexBox = styled(FlexBox)(({ theme }) => ({
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: 20,
    [theme.breakpoints.down(500)]: {
        width: "100%",
        "& .MuiInputBase-root": { maxWidth: "100%" },
        "& .MuiButton-root": {
            width: "100%",
            marginTop: 15,
        },
    },
}));

const TicketsNew: FC = () => {

    const navigate = useNavigate();

    const { orgId } = useParams();
    const orgName = atob(orgId);

    // change navbar title
    useTitle(`Ticket new (${orgName})`);
    
    const [searchTerm, setSearchTerm] = useState('');
    const [rows, setRows] = useState([]);
    const [status, setStatus] = useState('idle');

    const onKeyPressHandle = (event: any) => {
        if (event.which === 13) {
            event.preventDefault();
            setSearchTerm(event.target.value);
            setStatus('loading');
            var req: any = {
                url: '/v1/customers/customer/across/',
                params: { mobile_number: event.target.value, org_ids: (orgName === 'mwezi') ? 'OR000081,OR001014' : 'OR000001' }
            };
            searchCustomerAcrossCall(req).then((res: any) => {
                setRows(res.data.results);
                setStatus('loaded');
            }).catch((error: any) => {
                console.error(error);
                setStatus('loaded');
            });
        }
    };

    const handleResultRowClick = (_: any) => {
        navigate(`/dashboard/tickets/create/${_.account_no}/?orgId=${orgId}&callingNumber=${_.mobile_number}`)
      };

    // useEffect(() => {

    // }), [];

    return (
        <Box pt={2} pb={4}>

            <Box pt={2} pb={4}>
                <StyledFlexBox>
                    <SearchInput placeholder={`Search in ${orgName}..`} onKeyPress={e => onKeyPressHandle(e)} />
                </StyledFlexBox>
                {status !== 'loading' ?
                    <Small>Showing {rows.length} results for {searchTerm}</Small>
                    :
                    <></>
                }
            </Box>
            <Box pt={2} pb={4} sx={{ padding: 1, display: "flex", justifyContent: "space-around" }}>
                {status === 'loading' ?
                    <CircularProgress sx={{ maxWidth: '100%', margin: 2 }} />
                    :
                    ( (rows.length == 0) ? 
                        (
                            <Grid container spacing={3} mb={2} lg={12} md={12} sm={12} xs={12} >
                                <LightIconButton size="small"
                                    onClick={() => { navigate(`/dashboard/tickets/create/${0}/?orgId=${orgId}&callingNumber=${searchTerm}`) }}>
                                    <PlusIcon fontSize="small" />
                                </LightIconButton>
                            </Grid>
                        ) : 
                        (
                            <Grid container spacing={3} mb={2} lg={12} md={12} sm={12} xs={12} >
                                {rows.map((_, index) => {
                                    return <Grid item lg={4} md={6} sm={6} xs={12} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around", padding: 2 }} key={index}>
                                        <CustomerCard customer={_} onCardClick={handleResultRowClick} />
                                        <Divider sx={{ my: 1 }} />
                                    </Grid>
                                })}
                            </Grid>
                        )
                    )
                }
            </Box>
        </Box>
    );
};



export default TicketsNew;
