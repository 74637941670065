import { styled, IconButton, IconButtonProps } from "@mui/material";
import React from "react";

const StyledIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
    width: 100,
    padding: 2,
    height: 35,
    fontSize: 12,
    fontWeight: 500,
    borderRadius: "11px",
    backgroundColor: theme.palette.text.secondary,
}));

const LightIconButton = (props: IconButtonProps) => {
    return <StyledIconButton {...props} />;
};

export default LightIconButton;
