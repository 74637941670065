import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Card, Divider, Grid, styled, Tab } from "@mui/material";
import FlexBox from "components/FlexBox";
import NewTicketCard from "components/Tickets/NewTicketCard";
import { H6 } from "components/Typography";
import useTitle from "hooks/useTitle";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { v5 as uuidv5 } from 'uuid';

// styled components
const StyledCard = styled(Card)(() => ({
  position: "relative",
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: 13,
  color: theme.palette.text.primary,
}));

const StyledTabList = styled(TabList)(({ theme }) => ({
  [theme.breakpoints.down(780)]: {
    width: "100%",
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
    },
    marginBottom: 20,
  },
  [theme.breakpoints.up("sm")]: {
    "& .MuiTabs-flexContainer": {
      minWidth: 400,
      justifyContent: "space-between",
    },
  },
}));

const StyledTabPanel = styled(TabPanel)(() => ({
  padding: 0,
}));

const TicketsIndex: FC = () => {
  // change navbar title
  useTitle("Tickets");

  const [value, setValue] = useState("1");

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {

  });

  return (
    <Box pt={2} pb={4}>
      <TabContext value={value}>
        <StyledCard>
          <FlexBox
            flexWrap="wrap"
            padding="0 2rem"
            justifyContent="space-between"
          >
            <StyledTabList onChange={handleChange} sx={{ justifyContent: "normal" }}>
              <StyledTab label="Tickets creating" value="1" />
              <StyledTab label="Info and Help" value="2" />
            </StyledTabList>
          </FlexBox>
        </StyledCard>

        <Box marginTop={3}>
          <StyledTabPanel value="1" >
            {(forms).map((form) => {
              return <>
                <H6 sx={{ margin: 2 }}>{form.name}</H6>
                <Grid container spacing={3} >
                  {form.forms.map((_, index) => (
                    <Grid item lg={4} sm={6} xs={12} key={index}>
                      <NewTicketCard form={_} data={{}} />
                    </Grid>
                  ))}
                </Grid>
                <Divider sx={{ margin: 3 }} />
              </>
            })}
          </StyledTabPanel>
          <StyledTabPanel value="2">
            {/* <FormsInfo /> */}
          </StyledTabPanel>
        </Box>
      </TabContext>
    </Box>
  );
};

const forms = [
  {
    name: "Tickets approach",
    description: "",
    forms: [
      {
        name: "Mwezi Portfolio",
        description: "Create tickets for mwezi customers.",
        sub_routes: [
          {
            name: "New",
            url: `new/${btoa('mwezi')}`,
          },
          {
            name: "List",
            url: `/${btoa('mwezi')}`,
          }
        ]
      },
      {
        name: "Pawame (Ignite) Portfolio",
        description: "Create tickets for D100 customers.",
        sub_routes: [
          {
            name: "New",
            url: `new/${btoa('pawame ignite')}`,
          },
          {
            name: "List",
            url: `${btoa('pawame ignite')}`,
          }
        ]
      }
    ]
  },
];


export default TicketsIndex;
