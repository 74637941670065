import { styled, Button, ButtonProps } from "@mui/material";
import React from "react";

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
    width: 100,
    padding: 0,
    height: 35,
    fontSize: 12,
    fontWeight: 500,
    borderRadius: "11px",
    color: theme.palette.text.secondary,
}));

const LightButton = (props: ButtonProps) => {
    return <StyledButton {...props} />;
};

export default LightButton;
