import {
  Box,
  Card,
  Grid,
  MenuItem,
  Checkbox,
  InputLabel, ListItemText,
} from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import useTitle from "hooks/useTitle";
import { FC, useState, useEffect } from "react";
import * as Yup from "yup";
import LightSelectField from "components/LightSelectField";
import LightTextField from "components/LightTextField";
import { useFormik } from "formik";
import LightButton from "components/LightButton";
import { loggedInUser } from "utils/state";
import toast from "react-hot-toast";
import { saveSuvey } from "redux/customers";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const FormsD100CRPSurvey: FC = () => {
  const user = loggedInUser();
  const navigate = useNavigate();

  // change navbar title
  useTitle(`Customer referral program survey`);

  const tf_options: any = [
    { _k: 'yes', _v: "Yes" },
    { _k: 'no', _v: "No" }
  ];
  const [loading, setLoading] = useState(false);

  const initialValues = {
    phone_number: "",
    customer_available: "",
    product_satisfaction: 0,
    service_satisfaction: 0,
    likely_to_recommend: "",
    notes: "",
  };

  const validationSchema = Yup.object().shape({
    phone_number: Yup.string().required(`Phone No is required!`),
    customer_available: Yup.string().required(`Customer availability is required!`),
  });

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      var form = {
        url: 'v1/pwa/d100crpsurvey/',
        payload: {
          ...values,
        }
      };
      setLoading(true);
      saveSuvey(form).then((response) => {
        if (response?.status === 201) {
          toast.success('Survey saved');
          navigate('/dashboard/forms/');
        } else {
          toast.error("Something went wrong");
        }
        setLoading(false);
      }).catch((error) => {
        toast.error(error.message);
        setLoading(false);
      });
    },
  });

  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  const [customer_available, set_customer_available] = useState(false);

  useEffect(() => {
    set_customer_available((values.customer_available === 'yes'));
  }, [values]);
  
  const counties:any = ["Narok", "Kisumu", "Kericho", "Nandi", "Siaya", "Homa Bay", "Elgeyo Marakwet", "Baringo", "Bomet", "Uasin Gishu", "Kakamega", "Nakuru"];
  const rates_1_10: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const genNumbers = (_to: number) => {
    var _nums: number[] = [];
    for (let i = 1; i <= _to; i++) {
      _nums.push(i);
    }
    return _nums;
  };

  return (

    <Box pt={1} pb={1}>
      <Card sx={{ padding: 1 }}>
        <Grid container spacing={1}>
          <Grid item md={8} xs={12} lg={8}>
            <Card sx={{ padding: 2, boxShadow: 2 }}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="email"
                      size="small"
                      name="agent"
                      value={user?.email}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="number"
                      size="small"
                      name="phone_number"
                      label="Customer phone number"
                      value={values.phone_number}
                      onChange={handleChange}
                      error={Boolean(touched.phone_number && errors.phone_number)}
                      helperText={touched.phone_number && errors.phone_number}
                    />
                  </Grid>


                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="customer_available"
                      select
                      label="Is customer available?"
                      value={values.customer_available}
                      onChange={handleChange}
                      error={Boolean(touched.customer_available && errors.customer_available)}
                      helperText={touched.customer_available && errors.customer_available}
                    >
                      {(tf_options).map((option: any, index: any) => (
                        <MenuItem key={index} value={option._k}>
                          {option._v}
                        </MenuItem>
                      ))}
                    </LightTextField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="product_satisfaction"
                      select
                      label="Product satistfation"
                      value={values.product_satisfaction}
                      onChange={handleChange}
                      error={Boolean(touched.product_satisfaction && errors.product_satisfaction)}
                      helperText={touched.product_satisfaction && errors.product_satisfaction}
                    >
                      {genNumbers(10).map((_: number, index: any) => (
                        <MenuItem key={index} value={_}>
                          {_}
                        </MenuItem>
                      ))}
                    </LightTextField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="service_satisfaction"
                      select
                      label="Service satistfation"
                      value={values.service_satisfaction}
                      onChange={handleChange}
                      error={Boolean(touched.service_satisfaction && errors.service_satisfaction)}
                      helperText={touched.service_satisfaction && errors.service_satisfaction}
                    >
                      {genNumbers(10).map((_: number, index: any) => (
                        <MenuItem key={index} value={_}>
                          {_}
                        </MenuItem>
                      ))}
                    </LightTextField>
                  </Grid>


                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="likely_to_recommend"
                      select
                      label="Likely to recommend"
                      value={values.likely_to_recommend}
                      onChange={handleChange}
                      error={Boolean(touched.likely_to_recommend && errors.likely_to_recommend)}
                      helperText={touched.likely_to_recommend && errors.likely_to_recommend}
                    >
                      {genNumbers(5).map((_: number, index: any) => (
                        <MenuItem key={index} value={_}>
                          {_}
                        </MenuItem>
                      ))}
                    </LightTextField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      multiline
                      fullWidth
                      size="small"
                      rows={5}
                      name="notes"
                      label="Notes"
                      value={values.notes}
                      onChange={handleChange}
                      error={Boolean(touched.notes && errors.notes)}
                      helperText={touched.notes && errors.notes}
                      sx={{
                        "& .MuiOutlinedInput-root textarea": { padding: 0 },
                      }}
                    >
                    </LightTextField>
                  </Grid>


                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    {loading ? (
                      <LoadingButton loading fullWidth variant="contained">
                        Submit
                      </LoadingButton>
                    ) : (
                      <LightButton fullWidth type="submit" variant="contained">
                        Submit
                      </LightButton>
                    )}
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
export default FormsD100CRPSurvey;
