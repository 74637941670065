import AuthGuard from "components/authentication/AuthGuard";
import GuestGuard from "components/authentication/GuestGuard";
import DashboardLayout from "components/Layouts/DashboardLayout";
import FormsLayout from "components/Layouts/FormsLayout";
import LoadingScreen from "components/LoadingScreen";
import FormsFOCollectionSurvey from "pages/forms/fo/FormsFOCollectionSurvey";
import FormsAftercareInitialSurvey from "pages/forms/fo/FormsAftercareInitialSurvey";

import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router-dom";
import TicketsLayout from "components/Layouts/TicketsLayout";
import TicketsIndex from "pages/tickets/TicketsIndex";
import TicketsNew from "pages/tickets/TicketsNew";
import SMSLayout from "components/Layouts/SMSLayout";
import SmsIndex from "pages/sms/SmsIndex";
import TicketsCreateNew from "pages/tickets/TicketsCreateNew";
import FormsAftercareInitialSurveysList from "pages/forms/fo/FormsAftercareInitialSurveysList";
import InventoryLayout from "components/Layouts/InventoryLayout";
import InventoryIndex from "pages/inventory/InventoryIndex";
import SmsSend from "pages/sms/SmsSend";
import FormsPromo2LoanDealChange from "pages/forms/loan_deal_change/FormsPromo2LoanDealChange";
import FormsPromo2LoanDealChangeList from "pages/forms/loan_deal_change/FormsPromo2LoanDealChangeList";
import FormsD100CRPSurvey from "./pages/forms/d100/FormsD100CRPSurvey";
import FormsD100CVPSurvey from "./pages/forms/d100/FormsD100CVPSurvey";
import TicketList from "pages/tickets/TicketList";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// authentication pages
const Login = Loadable(lazy(() => import("./pages/authentication/Login")));

// Dashboard pages
const DashboardSaaS = Loadable(lazy(() => import("./pages/dashboards/SaaS")));

// forms
const FormsIndex = Loadable(
  lazy(() => import("./pages/forms/FormsIndex"))
);

// user profile
const UserProfile = Loadable(lazy(() => import("./pages/UserProfile")));

// user management
const UserList = Loadable(
  lazy(() => import("./pages/userManagement/UserList"))
);
const UserGrid = Loadable(
  lazy(() => import("./pages/userManagement/UserGrid"))
);
const AddNewUser = Loadable(
  lazy(() => import("./pages/userManagement/AddNewUser"))
);

// error
const Error = Loadable(lazy(() => import("./pages/404")));

// routes
const routes = [
  {
    path: "/",
    element: <Navigate to="dashboard" />,
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DashboardSaaS />,
      },
      {
        path: "user-profile",
        element: <UserProfile />,
      },
      {
        path: "forms",
        element: (
          <AuthGuard>
            <FormsLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: "",
            element: <FormsIndex />,
          },
          {
            path: "fo/collection-survey/new",
            element: <FormsFOCollectionSurvey />,
          },
          {
            path: "fo/collection-surveys",
            element: <FormsFOCollectionSurvey />,
          },
          {
            path: "fo/after-care/initial-survey/new",
            element: <FormsAftercareInitialSurvey />,
          },
          {
            path: "fo/after-care/initial-surveys",
            element: <FormsAftercareInitialSurveysList />,
          },
          {
            path: "fo/promo-2/installation/new",
            element: <FormsPromo2LoanDealChange />,
          },
          {
            path: "fo/promo-2/installations",
            element: <FormsPromo2LoanDealChangeList />,
          },
          {
            path: "fo/promo-2/installation/details/:formId",
            element: <FormsPromo2LoanDealChange />,
          },
          {
            path: "fo/d100-crp/new",
            element: <FormsD100CRPSurvey />,
          },
          {
            path: "fo/d100-cvp/new",
            element: <FormsD100CVPSurvey />,
          },
        ]
      },
      {
        path: "tickets",
        element: (
          <AuthGuard>
            <TicketsLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: "",
            element: <TicketsIndex />,
          },
          {
            path: ":orgId",
            element: <TicketList />,
          },
          {
            path: "new/:orgId",
            element: <TicketsNew />,
          },
          {
            path: "create/:accountId",
            element: <TicketsCreateNew />,
          },
        ]
      },
      {
        path: "sms",
        element: (
          <AuthGuard>
            <SMSLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: "",
            element: <SmsIndex />,
          },
          {
            path: "send",
            element: <SmsSend />,
          },
        ]
      },
      {
        path: "inventory",
        element: (
          <AuthGuard>
            <InventoryLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: "",
            element: <InventoryIndex />,
          },
        ]
      },
      {
        path: "user-list",
        element: <UserList />,
      },
      {
        path: "user-grid",
        element: <UserGrid />,
      },
      {
        path: "add-user",
        element: <AddNewUser />,
      },
    ],
  },
  {
    path: "*",
    element: <Error />,
  },
];

export default routes;
