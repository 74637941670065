const THEMES = {
  LIGHT: "light",
  DARK: "dark",
};

const TRANSLATIONS: any = {
  PAGES: {
    HOME: {
      TITLE: {
        'en': 'Welcome back',
        'fr': 'Content de te revoir',
        'pt': 'Content de te revoir',
      }
    }
  },
  GLOBAL: {
    LOGGED_IN: {
      'en': 'Logged in as',
      'fr': 'Connecté en tant que',
      'pt': 'Connecté en tant que',
    },
  },
  SIDEBAR: {
    HOME: {
      'en': 'Home',
      'fr': 'Maison',
      'pt': 'Maison',
    },
    FORMS: {
      'en': 'Home',
      'fr': 'Formes',
      'pt': 'Formas',
    }
  },
  FORMS: {
    GLOBAL: {
      REQUIRED: {
        'en': 'required',
        'fr': 'requis',
        'pt': 'requis',
      },
      YES: {
        'en': 'Yes',
        'fr': 'Oui',
        'pt': 'Oui',
      },
      NO: {
        'en': 'No',
        'fr': 'Non',
        'pt': 'Não',
      }
    },
    LOGIN: {
      TITLE: {
        'en': 'Sign In to Ignite',
        'fr': 'Se connecter à Ignite',
        'pt': 'Se connecter à Ignite',
      },
      FIELDS: {
        username: {
          'en': 'Username',
          'fr': 'Username',
          'pt': 'Username',
        },
        password: {
          'en': 'Password',
          'fr': 'Mot de passe',
          'pt': 'Mot de passe',
        },
      }
    },
    FOPS_CS: {
      TITLE: {
        'en': 'Field collection form',
        'fr': 'Formulaire de collecte de terrain',
        'pt': 'Formulaire de Collecte de Terrain',
      },
      FIELDS: {
        phone_number: {
          'en': 'Phone No',
          'fr': 'Pas de téléphone',
          'pt': 'Pas de téléphone',
        },
        customer_available: {
          'en': 'Is customer available?',
          'fr': 'Le client est-il disponible?',
          'pt': 'LE Client Est-il Danitible?',
        },
        reason_fo_unavailability: {
          'en': 'Reason for unavailability',
          'fr': "Raison de l'indisponibilité",
          'pt': "Raison de l'Indistonibilité",
          OPTIONS: [
            {
              'en': 'Number not available / Outof service',
              'fr': 'Numéro non disponible / Outof service',
              'pt': 'Numéro Non Dispponível / Off service',
            },
            {
              'en': 'Not picking',
              'fr': 'Ne pas cueillir',
              'pt': 'NE Pas Cueillir',
            },
            {
              'en': 'Wrong number',
              'fr': 'Mauvais numéro',
              'pt': 'Mauvais Numéro',
            },
            {
              'en': 'Person claims to have no engangement with us',
              'fr': 'La personne prétend avoir aucun engagement avec nous',
              'pt': 'La Personne Prétend Avoir Aucun noivado Avec nous',
            }
          ]
        },
        reason_for_not_paying: {
          'en': 'Reason for not paying',
          'fr': "Raison de ne pas payer",
          'pt': "Raison de NE Pas Payer",
          OPTIONS: [
            {
              'en': 'Paying in 7 days',
              'fr': 'Payer dans 7 jours',
              'pt': 'Pagador Dans 7 jours',
            },
            {
              'en': 'Paying in 14 days',
              'fr': 'Payer dans 14 jours',
              'pt': 'Pagador Dans 14 jours',
            },
            {
              'en': 'Customer not able to pay',
              'fr': 'Client incapable de payer',
              'pt': 'Cliente incapaz de pagador',
            },
            {
              'en': 'Customer not willing to pay and wants repossession',
              'fr': "Client qui n'est pas disposé à payer et veut une reprise de possession",
              'pt': "Cliente Qui N'est pasposé para payer et veut une reprise de possess",
            },
            {
              'en': 'Bought another solar home system',
              'fr': 'Acheté un autre système de maison solaire',
              'pt': 'Acheté un autre système de maison solaire',
            },
            {
              'en': 'Got the national grid',
              'fr': "J'ai la grille nationale",
              'pt': "J'ai la grille nationale",
            },
            {
              'en': 'Was repossessed',
              'fr': 'A été reproché',
              'pt': 'Um été reproché',
            },
            {
              'en': 'Was burnt / stolen',
              'fr': 'A été brûlé / volé',
              'pt': 'A été brûlé / volé',
            }
          ]
        },
        customer_accepted_to_pay: {
          'en': 'Has customer accepted to pay?',
          'fr': 'Le client a-t-il accepté de payer?',
          'pt': 'LE Client A-T-il Acepé de Payer?',
        },
        date_promised_to_pay: {
          'en': 'Date promised to pay',
          'fr': 'Date promise de payer',
          'pt': 'Date promise de payer',
        },
        explanation_for_not_paying: {
          'en': 'Explanation for not paying',
          'fr': 'Explication pour ne pas payer',
          'pt': 'Explicação Per',
        }
      }
    }
  }
};

export { THEMES, TRANSLATIONS }