import toast from "react-hot-toast";

var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
};

const getCurrentPosition = () => {
    return new Promise(function (resolve, reject) {
        if (navigator.geolocation) {
            navigator.permissions.query({ name: "geolocation" }).then(function (result) {
                if (result.state === "granted") {
                    navigator.geolocation.getCurrentPosition(resolve);
                } else if (result.state === "prompt") {
                    navigator.geolocation.getCurrentPosition(resolve, reject, options);
                } else if (result.state === "denied") {
                    reject("You dis-alloed location, some fuctionaity might not work well!")
                }
                result.onchange = function () {
                    console.log(result.state);
                };
            });
        }
    });
};

const setLoginPosition = (coords: any) => {
    localStorage.setItem('loginGeoPosition', JSON.stringify(coords));
};

const getLoginPosition = () => {

};

export { getCurrentPosition, getLoginPosition, setLoginPosition };
