import { Button, Card, CardActions, CardContent, styled, useTheme } from "@mui/material";
import FlexBox from "components/FlexBox";
import LightButton from "components/LightButton";
import LightIconButton from "components/LightIconButton";
import { H4, H6, Small } from "components/Typography";
import ListIcon from "icons/ListIcon";
import PlusIcon from "icons/PlusIcon";
import React, { FC } from "react";
import { Link as RouterLink, useNavigate } from 'react-router-dom';


// component props interface
interface Props {
  form: {
    name?: string;
    description?: string;
    sub_routes?: any;
  },
  data: any
};

const NewTicketCard: FC<Props> = (form, data) => {
  const navigate = useNavigate();

  const theme = useTheme();
  // button background color
  const backgroundColor =
    theme.palette.mode === "light" ? "secondary.200" : "divider";
  // button border color
  const borderColor =
    theme.palette.mode === "light" ? "secondary.200" : "divider";


  return (
    // <div style={{ background: 'linear-gradient(to right, rgba(102, 126, 234, 0.5), rgba(118, 75, 162, 0.5))', minHeight: '100%' }}>
    <Card sx={{ textAlign: "center", padding: 3 }}>
      <CardContent>
        <H4 mt={2}>{form.form?.name}</H4>
        <Small color="text.disabled">{form.form?.description}</Small>
      </CardContent>
      <CardActions
        sx={{ display: "flex", justifyContent: "space-evenly", alignContent: "center" }}>
        {(form.form?.sub_routes).map((option: any) => {
          return <LightIconButton size="small"
            onClick={() => { navigate(`/dashboard/tickets/${option?.url}`) }}>
            {(option?.name) === "New" ? <PlusIcon fontSize="small" /> : <ListIcon fontSize="small" />}
            {option?.name}
          </LightIconButton>
        })}
      </CardActions>
    </Card >
    // </div>
  );
};

export default NewTicketCard;
