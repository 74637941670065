import {
  AppBar,
  Box,
  Button,
  Card,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Slide,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import useTitle from "hooks/useTitle";
import React, { FC, useState, useEffect } from "react";
import * as Yup from "yup";
import LightTextField from "components/LightTextField";
import { useFormik } from "formik";
import LightButton from "components/LightButton";
import { loggedInUser } from "utils/state";
import toast from "react-hot-toast";
import { saveSuvey } from "redux/customers";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
// import CameraSnap from "components/CameraSnap";
import LightIconButton from "components/LightIconButton";
import CaptureIcon from "icons/CaptureIcon";

import CloseIcon from "icons/CloseIcon";
import { TransitionProps } from '@mui/material/transitions';
import Webcam from "react-webcam";
import { saveForm, viewForm } from "redux/forms";
import { uploadImage } from "redux/images";




const FormsPromo2LoanDealChange: FC = () => {
  const user = loggedInUser();
  const navigate = useNavigate();

  // change navbar title
  useTitle(`Promotion 2 Deal Change (New Installation)`);

  const { formId } = useParams();


  const customerIDPhoto = React.useRef(null);
  const [customerIDPhotoSrc, setCustomerIDPhotoSrc] = useState(null);

  const customerHoldingIDPhoto = React.useRef(null);
  const [customerSelfieIDPhotoSrc, setCustomerSelfieIDPhotoSrc] = useState(null);


  const customerWithInstalledPhoto = React.useRef(null);
  const [customerWithInstalledPhotoSrc, setCustomerWithInstalledPhotoSrc] = useState(null);

  const [curWebCamRef, setCurWebCamRef] = useState(null);
  const [curWebCamCapturing, setCurWebCamCapturing] = useState('');

  const [openCam, setOpenCam] = useState(false);


  const handleCapture = React.useCallback(
    () => {
      const imageSrc = curWebCamRef.current.getScreenshot();
      if (curWebCamCapturing === 'PHOTO_OF_ID') {
        setCustomerIDPhotoSrc(imageSrc);
      }
      else if (curWebCamCapturing === 'PHOTO_OF_CUSTOMER_WITH_ID') {
        setCustomerSelfieIDPhotoSrc(imageSrc);
      }
      else if (curWebCamCapturing === 'PHOTO_OF_CUSTOMER_AND_INSTALLED_DEVICE') {
        setCustomerWithInstalledPhotoSrc(imageSrc);
      }
      curWebCamRef.current.stream.getTracks().forEach((track: any) => track.stop());
      setOpenCam(false);

    },
    [curWebCamRef]
  );

  const onUserMedia = () => {
    // toast.success('Test');
  };

  const newDeals: any = ['Promo-2'];

  const [loading, setLoading] = useState(false);
  const [loadedForm, setLoadedForm] = useState(null);
  const [formLoading, setFormLoading] = useState('idle');


  const dataURLtoBlob = (dataurl: any) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const Formchema = Yup.object().shape({

  });


  const formik = useFormik({
    initialValues: {
      ticket: "",
      new_deal: "",
      mpesa_transaction_code: ""
    },
    validationSchema: Formchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);

      var formData = new FormData();
      const validExtensions = ['docx', 'jpg', 'jpeg', 'png'];
      formData.append('validExtensions', JSON.stringify(validExtensions));
      formData.append('realPath', '/var/www/cloud-a/data-center/geosat/c3b10d9d-db06-4a12-8c8d-54e80f35ac12/mvs/receipts/pwa/');
      formData.append('absolutePath', 'https://wingu.ivaliu.co.ke/data-center/geosat/c3b10d9d-db06-4a12-8c8d-54e80f35ac12/mvs/receipts/pwa/');
      [customerIDPhotoSrc, customerSelfieIDPhotoSrc].map((_: any) => {
        if (_ === null) {
          toast.error('An image is missing');
          return;
        }
        formData.append('files[]', dataURLtoBlob(_));
      });

      var form = {
        url: 'https://wingu.ivaliu.co.ke/file-uploader/api/v1/',
        payload: formData
      };
      uploadImage(form).then((response: any) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          let uploads = response.data.data.urls;
          var form = {
            url: 'v1/pwa/loandealchange/',
            payload: {
              uploads: uploads,
              ...values
            }
          };
          saveForm(form).then((response) => {
            if (response?.status === 201) {
              toast.success('Survey saved');
              navigate('/dashboard/forms/');
            } else {
              toast.error("Something went wrong");
            }
            setLoading(false);
          }).catch((error) => {
            toast.error(error.message);
            setLoading(false);
          });
        } else {
          toast.error(response.data.errors.join(", "));
          setLoading(false);
        }
      }).catch((error: any) => {
        toast.error(error);
        setLoading(false);

      });
    }
  });


  const {
    errors, touched, values, initialValues,
    handleSubmit, getFieldProps
  } = formik;

  const updateUI = (_: any) => {
    formik.setValues(_);
    if (_.uploads !== null) {
      _.uploads.map((__: any, index: any) => {
        var src = __.absolutePath;
        (index == 0) ? setCustomerIDPhotoSrc(src) : setCustomerSelfieIDPhotoSrc(src);
      });
    }
  };

  useEffect(() => {
    if (formId !== undefined && formLoading !== 'loaded') {
      setFormLoading('loading');
      // load form with ID
      var req = {
        url: `v1/pwa/loandealchange/${formId}/`,
      };
      viewForm(req).then((response: any) => {
        if (response.status === 200) {
          setLoadedForm(response.data);
          updateUI(response.data);
        }
        setFormLoading('loaded');
      }).catch((error: any) => {
        setFormLoading('loaded');
      });
      setFormLoading('loaded');
    }
  }, [formik, curWebCamRef, loadedForm, formLoading]);


  interface ViewProps {
    state: boolean;
    webcamRef: any;
    onUserMedia: (_: any) => void;
    handleCapture: (_: any) => void;
    handleCloseDrawer: () => void;
  };



  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStageClick = (_: number) => {
    setActiveStep(_);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const CameraSnap: FC<ViewProps> = (props) => {

    const { state, webcamRef, onUserMedia, handleCapture, handleCloseDrawer } = props;

    const Transition = React.forwardRef(function Transition(
      props: TransitionProps & {
        children: React.ReactElement;
      },
      ref: React.Ref<unknown>,
    ) {
      return <Slide direction="up" ref={ref} {...props} />;
    });

    const [camSide, setCamSide] = useState('front');
    const [videoConstraints, setVideoConstraints] = useState(null);

    return <Dialog
      open={state}
      onClose={handleCloseDrawer}
      TransitionComponent={Transition}
      title="Capture image"
      fullScreen={true}
    >
      <AppBar sx={{ position: 'relative', backgroundColor: "background.secondary" }}>
        <Toolbar>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseDrawer}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box pt={1} pb={1}>
        <Card sx={{ padding: 1 }}>
          <Grid container spacing={1}>
            <Grid container spacing={1}>
              <Grid item sm={12} xs={12} lg={6} md={6}>
                <LightTextField
                  fullWidth
                  id='camSide'
                  type='text'
                  label="Camera"
                  select
                  size="small"
                  onChange={(_: any) => {
                    (_.target.value === 'back') ?
                      setVideoConstraints(
                        {
                          width: 100,
                          height: 100,
                          facingMode: { exact: "environment" }
                        }
                      ) : setVideoConstraints(
                        {
                          width: 100,
                          height: 100,
                          facingMode: "user"
                        }
                      );
                  }}
                >
                  {['front', 'back'].map((_: any, index: any) => (
                    <MenuItem key={index} value={_} selected={(_ == camSide)}>
                      {_}
                    </MenuItem>
                  ))}
                </LightTextField>
              </Grid>

              <Grid item sm={12} xs={12} boxShadow={2}>
                <Webcam
                  audio={false}
                  height={600}
                  ref={webcamRef}
                  screenshotFormat="image/png"
                  width={600}
                  videoConstraints={videoConstraints || {
                    width: 100,
                    height: 100,
                    facingMode: "user"
                  }}
                  disabled={false}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <LightButton fullWidth variant="contained" onClick={handleCapture}>
                  Capture
                </LightButton>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Dialog>
  };


  return (
    <Box pt={1} pb={1}>
      <form onSubmit={handleSubmit}>

        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key={1}>
            <StepLabel sx={{ fontWeight: "bold" }}>
              Loan details and agreement (#1)
            </StepLabel>
            <StepContent>
              <Typography sx={{ fontWeight: "bold" }}>Please complete the form with all information</Typography>
              <Card sx={{ padding: 1 }}>
                <Grid container spacing={1}>
                  <Grid item md={8} xs={12} lg={8}>
                    <Card sx={{ padding: 2, boxShadow: 2 }}>
                      <Grid container spacing={1}>

                        <Grid item sm={12} xs={12} hidden={true}>
                          <LightTextField
                            fullWidth
                            type="email"
                            size="small"
                            name="agent"
                            value={user?.email}
                            label="Logged in as"
                            disabled={true}
                          />
                        </Grid>

                        <Grid item sm={12} xs={12}>
                          <LightTextField
                            fullWidth
                            type="number"
                            size="small"
                            name="ticket"
                            label="Ticket No"
                            {...getFieldProps('ticket')}
                            error={Boolean(touched.ticket && errors.ticket)}
                            helperText={touched.ticket && errors.ticket}
                          />
                        </Grid>

                        {/* Auto filled here (Loan and Customer Info)*/}

                        <Grid item sm={12} xs={12}>

                        </Grid>

                        {/* Photos capturing here */}
                        <Grid item sm={12} xs={12} lg={4} md={6}>
                          <Box boxShadow={2} sx={{ width: 220, height: 220, padding: "0.2rem" }}>
                            <img
                              src={customerIDPhotoSrc}
                              alt="National ID"
                              width="100%"
                              height="100%"
                            />
                          </Box>
                          <LightIconButton onClick={() => { setCurWebCamRef(customerIDPhoto); setOpenCam(true); setCurWebCamCapturing('PHOTO_OF_ID'); }} size="small" sx={{ marginTop: 1, width: 220 }}>
                            <CaptureIcon fontSize="small" />
                            Pic National ID
                          </LightIconButton>
                          <CameraSnap state={openCam} webcamRef={customerIDPhoto} onUserMedia={onUserMedia} handleCapture={handleCapture} handleCloseDrawer={() => { setOpenCam(false); }} />
                        </Grid>


                        <Grid item sm={12} xs={12} lg={4} md={6}>
                          <Box boxShadow={2} sx={{ width: 220, height: 220, padding: "0.2rem" }}>
                            <img
                              src={customerSelfieIDPhotoSrc}
                              alt="Close-up pic holding ID"
                              width="100%"
                              height="100%"
                            />
                          </Box>
                          <LightIconButton onClick={() => { setCurWebCamRef(customerHoldingIDPhoto); setOpenCam(true); setCurWebCamCapturing('PHOTO_OF_CUSTOMER_WITH_ID'); }} size="small" sx={{ marginTop: 1, width: 220 }}>
                            <CaptureIcon fontSize="small" />
                            Close-up holding National ID
                          </LightIconButton>
                          <CameraSnap state={openCam} webcamRef={customerHoldingIDPhoto} onUserMedia={onUserMedia} handleCapture={handleCapture} handleCloseDrawer={() => { setOpenCam(false); }} />
                        </Grid>

                        <Grid item sm={12} xs={12}>
                          <LightTextField
                            fullWidth
                            type="text"
                            size="small"
                            name="new_deal"
                            label="New Deal"
                            {...getFieldProps('new_deal')}
                          >
                          </LightTextField>
                        </Grid>


                        <Grid item sm={12} xs={12}>
                          <LightTextField
                            fullWidth
                            type="text"
                            size="small"
                            name="mpesa_transaction_code"
                            label="Mpesa Transation Code"
                            {...getFieldProps('mpesa_transaction_code')}
                            error={Boolean(touched.mpesa_transaction_code && errors.mpesa_transaction_code)}
                            helperText={touched.mpesa_transaction_code && errors.mpesa_transaction_code}

                          />
                        </Grid>


                        <Grid item sm={12} xs={12} hidden={(loadedForm === null)}>
                          <LightTextField
                            fullWidth
                            type="text"
                            size="small"
                            name="sale_status"
                            label="Sale staus"
                            {...getFieldProps('sale_status')}
                            disabled={true}
                          />
                        </Grid>

                        <Grid item sm={12} xs={12} hidden={(loadedForm === null)}>
                          <LightTextField
                            fullWidth
                            type="text"
                            size="small"
                            name="sale_status_updated_by"
                            label="Sale staus by"
                            {...getFieldProps('sale_status_updated_by.username')}
                            disabled={true}
                          />
                        </Grid>

                        <Grid item sm={12} xs={12} hidden={(loadedForm === null)}>
                          <LightTextField
                            fullWidth
                            multiline
                            rows={4}
                            type="text"
                            size="small"
                            name="sale_status_remarks"
                            label="Remarks"
                            {...getFieldProps('sale_status_remarks')}
                            disabled={true}
                          />
                        </Grid>


                        {(loadedForm !== null && loadedForm.sale_status === 'approved') ?
                          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around' }} >
                            <LightButton
                              variant="contained"
                              fullWidth
                              onClick={handleNext}
                            >
                              Next stage
                            </LightButton>
                          </Grid>
                          :
                          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                            {loading ? (
                              <LoadingButton loading fullWidth variant="contained">
                                Submit
                              </LoadingButton>
                            ) : (
                              <LightButton fullWidth type="submit" variant="contained">
                                Submit
                              </LightButton>
                            )}
                          </Grid>
                        }

                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Card>
            </StepContent>
          </Step>
          <Step key={2}>
            <StepLabel sx={{ fontWeight: "bold" }}>
              New installation details (#2)
            </StepLabel>
            <StepContent>
              <Typography sx={{ fontWeight: "bold" }}>Please complete the form with all information. Photos are important</Typography>
              <Card sx={{ padding: 1 }}>
                <Grid container spacing={1}>
                  <Grid item md={8} xs={12} lg={8}>
                    <Card sx={{ padding: 2, boxShadow: 2 }}>
                      <Grid container spacing={1}>

                        {/* Auto filled here (Loan and Customer Info)*/}

                        <Grid item sm={12} xs={12}>

                        </Grid>

                        {/* Photos capturing here */}
                        <Grid item sm={12} xs={12} lg={4} md={6}>
                          <Box boxShadow={2} sx={{ width: 220, height: 220, padding: "0.2rem" }}>
                            <img
                              src={customerWithInstalledPhotoSrc}
                              alt="Close-up pic customer next to installed battery"
                              width="100%"
                              height="100%"
                            />
                          </Box>
                          <LightIconButton onClick={() => { setCurWebCamRef(customerWithInstalledPhoto); setOpenCam(true); setCurWebCamCapturing('PHOTO_OF_CUSTOMER_AND_INSTALLED_DEVICE'); }} size="small" sx={{ marginTop: 1, width: 220 }}>
                            <CaptureIcon fontSize="small" />
                            Customer with installed
                          </LightIconButton>
                          <CameraSnap state={openCam} webcamRef={customerWithInstalledPhoto} onUserMedia={onUserMedia} handleCapture={handleCapture} handleCloseDrawer={() => { setOpenCam(false); }} />
                        </Grid>

                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                          <LightButton
                            onClick={handleBack}
                            variant="contained"
                          >
                            Back
                          </LightButton>
                          {loading ? (
                            <LoadingButton loading fullWidth variant="contained">
                              Submit
                            </LoadingButton>
                          ) : (
                            <LightButton fullWidth type="submit" variant="contained">
                              Submit
                            </LightButton>
                          )}
                        </Grid>



                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Card>
            </StepContent>
          </Step>
        </Stepper>
      </form>

    </Box>
  );
};
export default FormsPromo2LoanDealChange;
