import {
    AppBar,
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Slide,
    Toolbar,
    Typography,
    useTheme
} from "@mui/material";
import useTitle from "hooks/useTitle";
import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import LightTextField from "components/LightTextField";
import { useFormik } from "formik";
import LightButton from "components/LightButton";
import { loggedInUser } from "utils/state";
import { H4, H6, Small } from "components/Typography";
import { DiagnosticCategory } from "typescript";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useIsMountedRef from "hooks/useIsMountedRef";
import { accountData } from "redux/accounts";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { saveTicket } from "redux/tickets";
import { LoadingButton } from "@mui/lab";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "icons/CloseIcon";
import ShareIcon from "icons/ShareIcon";
import toast from "react-hot-toast";

const TicketsCreateNew: FC = () => {
    const navigate = useNavigate();

    const user = loggedInUser();

    const theme = useTheme();
    const [searchParams, setSearchParams] = useSearchParams();
    const { accountId } = useParams();
    const isMountedRef = useIsMountedRef();

    

    let callingNumber = searchParams.get("callingNumber");
    let orgId = searchParams.get("orgId");

    const orgName = atob(orgId);

    // change navbar title
    useTitle(`Create ticket for ${callingNumber} in ${orgName}`);

    const titles = [
        { _id: 'Product Malfunction', _: 'Product malfunction' },
        { _id: 'Payment Issue', _: 'Payment issue' },
        { _id: 'Incomplete Registration', _: 'Incomplete registration' },
        { _id: 'Refferals', _: 'Refferals' },
        { _id: 'Repossessions', _: 'Repossessions' },
    ];

    const components = [
        { _id: 'solar panel', _: 'Solar panel' },
        { _id: 'battery', _: 'Battery' },
        { _id: 'torch', _: 'Torch' },
        { _id: 'tv', _: 'TV' },
        { _id: 'lamp', _: 'Lamp' }
    ];

    const statuses = [
        { _id: 'open', _: 'Open' },
        { _id: 'in progress', _: 'In Progress' },
        { _id: 'closed', _: 'Closed' },
    ];

    const priorities = [
        { _id: 'low', _: 'Low' },
        { _id: 'normal', _: 'Normal' },
        { _id: 'high', _: 'High' },
        { _id: 'urgent', _: 'Urgent' },
    ];

    const surveyQuesIO = [
        { _id: 'yes', _: 'Yes' },
        { _id: 'no', _: 'No' }
    ];

    const escalation = [
        { _id: 'technical', _: 'Technical' },
        { _id: 'inventory', _: 'Inventory' },
        { _id: 'field', _: 'Field' }
    ];


    const Formchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        call_in_phone_number: Yup.string().required('Call in phone number is required'),
        priority: Yup.string().required('Priority is required'),
        description: Yup.string().required('Description is required'),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            component: '',
            call_in_phone_number: callingNumber,
            status: 'open',
            priority: 'normal',
            description: '',
            resolution: '',
            tags: [callingNumber],
            product_in_household: '',
            product_working: '',
            scheduled_visit: '',
            escalate_to: null,
            created_by: user?.id,
        },
        validationSchema: Formchema,
        enableReinitialize: true,
        onSubmit: async (values, { resetForm }) => {
            setSubmitting(true);
            try {
                const payload = {
                    ...values,
                };
                if (orgName == 'mwezi') {
                    Object.assign(payload, { associated_account: account?.id });
                    Object.assign(payload, { 'origin_account_type': 'mwezi' });
                    
                }else{
                    Object.assign(payload, { solrm_account: account?.id });
                    Object.assign(payload, { 'origin_account_type': 'solrm' });
                }
                const form = {
                    url: '/v1/portfolio_health/associated-account-ticket/',
                    payload
                };
                saveTicket(form).then((response: any) => {
                    if (response.status === 201) {
                        toast.success('Saved');
                        resetForm();
                        navigate(`/dashboard/tickets`);
                    }
                    setSubmitting(false);

                }).catch((error: any) => {
                    setSubmitting(false);
                })

            } catch (error) {
                if (isMountedRef.current) {
                    setSubmitting(false);
                }
            }

        }
    });

    const [isSubmitting, setSubmitting] = useState(false);

    const {
        errors, touched, values, initialValues,
        handleSubmit, getFieldProps
    } = formik;

    const [component_io, setComponentio] = useState(false);
    const [state, setState] = useState('loading');
    const [account, setAccount] = useState(null);

    useEffect(() => {

        if (state !== 'loaded') {
            var req = {
                url: (orgName == 'mwezi') ? `/v1/portfolio_health/associated-account/?account_no=${accountId}` : `/v1/solrm/account/?account_no=${accountId}`
            };
            accountData(req).then((response: any) => {
                if (response?.status === 200) {
                    setAccount(response?.data.results[0]);
                }
                setState('loaded');
            }).catch((error: any) => {
                setState('loaded');
            });
        }
        setComponentio((values.title.toLowerCase() === 'product malfunction'));
        formik.setFieldValue('component', (values.title.toLowerCase() === 'product malfunction') ? values.component : '');

    }, [values, account]);




    // Dialog
    const [open, setOpen] = useState(false);
    const [curViewRow, setCurViewRow] = useState(null);

    const handleRowClicked = (row: any) => {
        setOpen(true);
        setCurViewRow(row);
    };

    const handleCloseDrawer = () => {
        setOpen(false);
        setCurViewRow(null);
    };

    const DialogInfo: FC = () => {

        const Transition = React.forwardRef(function Transition(
            props: TransitionProps & {
                children: React.ReactElement;
            },
            ref: React.Ref<unknown>,
        ) {
            return <Slide direction="up" ref={ref} {...props} />;
        });


        const objectValue = (obj: any, _: string) => {
            return (obj !== null) ? (typeof (obj[_]) === "object") ? (['partner', 'partner_branch'].includes(_)) ? obj[_]['name'] : '' : (obj !== null) ? obj[_] : '' : '';
        };

        const objectToLines = (obj: any) => {
            var lines = ``;
            if (obj !== null) {
                lines += `*New ${obj._}*`;
                lines += `\n\n`;
                Object.keys(curViewRow || []).map((_) => {
                    lines += `*${_.replaceAll("_", " ").toUpperCase()}* - ${objectValue(curViewRow || null, _)}`;
                    lines += `\n`;
                });
                lines += `*Posted by ${user?.email}*`;
            }
            return lines;
        };

        const handleShare = async () => {
            var message = objectToLines(curViewRow);
            if (navigator.share) {
                try {
                    await navigator.share({
                        title: `New ${curViewRow._}`,
                        text: `${message}`,
                    });
                    toast.success('Shared successfully.');
                } catch (error) {
                    toast.error(`Error sharing ${error}`);
                }
            } else {
                toast.error('Web Share API not supported');
                let url = `https://web.whatsapp.com/send?group=`;
                url += `&text=${encodeURI(message)}&app_absent=0`;
                window.open(url);
            }
        };

        return <Dialog
            open={open}
            onClose={handleCloseDrawer}
            TransitionComponent={Transition}
            fullWidth
        >
            <AppBar sx={{ position: 'relative', backgroundColor: "background.secondary" }}>
                <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleShare}
                        aria-label="close"
                    >
                        <ShareIcon />
                    </IconButton>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseDrawer}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {(curViewRow !== null || curViewRow !== undefined) ?
                <List>
                    {Object.keys(curViewRow || []).map((_) => {
                        return <ListItem alignItems="flex-start">
                            <ListItemText
                                primary={_.replaceAll("_", " ").toUpperCase()}
                                secondary={
                                    <React.Fragment>
                                        <Typography variant="body2" color="text.primary" sx={{ fontWeight: 'bold' }}>
                                            {objectValue(curViewRow || null, _)}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    })}

                </List>
                : <></>}
        </Dialog>

    };


    // 
    return (
        <Box pt={1} pb={1}>
            <Card sx={{ padding: 1 }}>
                <Grid container spacing={1}>
                    <Grid item md={5} xs={12} lg={5}>
                        {(state === "loading") ?
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <CircularProgress />
                            </Box>
                            :
                            <>
                                <Card sx={{ padding: 2, boxShadow: 2 }}>
                                    <H6 sx={{ color: theme.palette.success.dark }}>Account details</H6>
                                    <CardContent>
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            flexDirection: "column",
                                            textAlign: "left"
                                        }}>
                                            <Small>{account?.payment_plan}</Small>
                                            <Small>Plan: {account?.account_status}</Small>
                                            <Small>#{account?.account_no}</Small>
                                            <Small>Paid: {account?.total_paid}/=</Small>
                                            <Small>Payoff: {account?.payoffamount}/=</Small>

                                        </Box>
                                    </CardContent>
                                </Card>
                                <Divider sx={{ my: 2 }} />
                                <Card sx={{ padding: 2, boxShadow: 2 }}>
                                    <H6 sx={{ color: theme.palette.success.dark }}>Current tickets</H6>
                                    <CardContent>
                                        {account?.tickets?.map((_: any, index: any) => {
                                            return (<>
                                                <ListItem key={index} alignItems="flex-start" sx={{
                                                    borderBottom: 0.5,
                                                    cursor: "pointer"
                                                }} onClick={((rowData: object) => handleRowClicked({ ..._, _: 'Ticket' }))}>
                                                    <ListItemText
                                                        primary={<React.Fragment><Typography sx={{ fontWeight: 'bolder' }}>{_?.title}</Typography></React.Fragment>}
                                                        key={index}
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography sx={{ fontWeight: 'bold' }} variant="body2" color="text.primary">
                                                                    #: {_?.id}
                                                                </Typography>
                                                                {/* <Typography variant="body2" color="text.primary">
                                                                    Product: {_?.component}
                                                                </Typography> */}
                                                                <Typography variant="body2" color="text.primary">
                                                                    Status: {_?.status}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.primary">
                                                                    Created on: {(_?.date !== undefined) ? (moment(new Date(_?.date).toLocaleString()).format("YYYY-MM-DD") != '1970-01-01') ? moment(new Date(_?.date).toLocaleString()).format("YYYY-MM-DD") : 'None' : 'None'}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.primary">
                                                                    Priority: {_?.priority}
                                                                </Typography>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                            </>)
                                        })}
                                    </CardContent>
                                </Card>
                                <Divider sx={{ my: 2 }} />
                                <Card sx={{ padding: 2, boxShadow: 2 }}>
                                    <H6 sx={{ color: theme.palette.success.dark }}>Current surveys</H6>
                                    <CardContent>
                                        {account?.survey?.map((_: any, index: any) => {
                                            return (<>
                                                <ListItem key={index} alignItems="flex-start" sx={{
                                                    borderBottom: 0.5,
                                                    cursor: "pointer"
                                                }} onClick={((rowData: object) => handleRowClicked({ ..._, _: 'Survey' }))}>
                                                    <ListItemText
                                                        primary={_?.customer_available}
                                                        key={index}
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography variant="body2" color="text.primary">
                                                                    Created on: {(_?.date_created !== undefined) ? (moment(new Date(_?.date_created).toLocaleString()).format("YYYY-MM-DD") != '1970-01-01') ? moment(new Date(_?.date_created).toLocaleString()).format("YYYY-MM-DD") : 'None' : 'None'}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.primary">
                                                                    Phone #: {_?.phone_number_used}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.primary">
                                                                    Reason for not paying: {_?.reason_for_not_paying}
                                                                </Typography>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                            </>)
                                        })}
                                    </CardContent>
                                </Card>
                            </>
                        }
                    </Grid>
                    <Grid item md={7} xs={12} lg={7}>
                        <Card sx={{ padding: 2, boxShadow: 2 }}>
                            <H6 sx={{ color: theme.palette.success.dark }}>New ticket details</H6>
                            <CardContent>
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={1}>
                                        <Grid item sm={12} xs={12}>
                                            <LightTextField
                                                fullWidth
                                                type="text"
                                                size="small"
                                                label="Loggedin as"
                                                value={user?.email}
                                                disabled={true}
                                            />
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <LightTextField
                                                fullWidth
                                                id='call_in_phone_number'
                                                type='text'
                                                label="Phone number used to call"
                                                disabled
                                                size="small"
                                                {...getFieldProps('call_in_phone_number')}
                                                error={Boolean(touched.call_in_phone_number && errors.call_in_phone_number)}
                                                helperText={touched.call_in_phone_number && errors.call_in_phone_number}
                                            />
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <LightTextField
                                                fullWidth
                                                id='title'
                                                type='text'
                                                label="Title"
                                                select
                                                size="small"
                                                {...getFieldProps('title')}
                                                error={Boolean(touched.title && errors.title)}
                                                helperText={touched.title && errors.title}
                                            >
                                                {titles.map((_: any, index: any) => (
                                                    <MenuItem key={index} value={_._id}>
                                                        {_._}
                                                    </MenuItem>
                                                ))}
                                            </LightTextField>
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <LightTextField
                                                fullWidth
                                                id='component'
                                                type='text'
                                                label="Component"
                                                select
                                                size="small"
                                                {...getFieldProps('component')}
                                                error={Boolean(touched.component && errors.component)}
                                                helperText={touched.component && errors.component}
                                                disabled={!component_io}
                                            >
                                                {components.map((_: any, index: any) => (
                                                    <MenuItem key={index} value={_._id}>
                                                        {_._}
                                                    </MenuItem>
                                                ))}
                                            </LightTextField>
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <LightTextField
                                                fullWidth
                                                multiline
                                                rows={4}
                                                id='description'
                                                type='text'
                                                label="Description"
                                                size="small"
                                                {...getFieldProps('description')}
                                                error={Boolean(touched.description && errors.description)}
                                                helperText={touched.description && errors.description}
                                            />
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <LightTextField
                                                fullWidth
                                                multiline
                                                rows={4}
                                                id='resolution'
                                                type='text'
                                                label="Resolution"
                                                size="small"
                                                {...getFieldProps('resolution')}
                                                error={Boolean(touched.resolution && errors.resolution)}
                                                helperText={touched.resolution && errors.resolution}
                                            />
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <LightTextField
                                                fullWidth
                                                id='status'
                                                type='text'
                                                label="Status"
                                                select
                                                size="small"
                                                {...getFieldProps('status')}
                                                error={Boolean(touched.status && errors.status)}
                                                helperText={touched.status && errors.status}
                                            >
                                                {statuses.map((_: any, index: any) => (
                                                    <MenuItem key={index} value={_._id} selected={(_._id === 'open')}>
                                                        {_._}
                                                    </MenuItem>
                                                ))}
                                            </LightTextField>
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <LightTextField
                                                fullWidth
                                                id='priority'
                                                type='text'
                                                label="Priority"
                                                select
                                                size="small"
                                                {...getFieldProps('priority')}
                                                error={Boolean(touched.priority && errors.priority)}
                                                helperText={touched.priority && errors.priority}
                                            >
                                                {priorities.map((_: any, index: any) => (
                                                    <MenuItem key={index} value={_._id} selected={(_._id === 'normal')}>
                                                        {_._}
                                                    </MenuItem>
                                                ))}
                                            </LightTextField>
                                        </Grid>


                                        <Grid item sm={12} xs={12}>
                                            <LightTextField
                                                fullWidth
                                                id='product_in_household'
                                                type='text'
                                                label="Is product in house hold"
                                                select
                                                size="small"
                                                {...getFieldProps('product_in_household')}
                                                error={Boolean(touched.product_in_household && errors.product_in_household)}
                                                helperText={touched.product_in_household && errors.product_in_household}
                                            >
                                                {surveyQuesIO.map((_: any, index: any) => (
                                                    <MenuItem key={index} value={_._id}>
                                                        {_._}
                                                    </MenuItem>
                                                ))}
                                            </LightTextField>
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <LightTextField
                                                fullWidth
                                                id='product_working'
                                                type='text'
                                                label="Is product working"
                                                select
                                                size="small"
                                                {...getFieldProps('product_working')}
                                                error={Boolean(touched.product_working && errors.product_working)}
                                                helperText={touched.product_working && errors.product_working}
                                            >
                                                {surveyQuesIO.map((_: any, index: any) => (
                                                    <MenuItem key={index} value={_._id}>
                                                        {_._}
                                                    </MenuItem>
                                                ))}
                                            </LightTextField>
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <LightTextField
                                                fullWidth
                                                id='scheduled_visit'
                                                type='text'
                                                label="Is visit scheduled"
                                                select
                                                size="small"
                                                {...getFieldProps('scheduled_visit')}
                                                error={Boolean(touched.scheduled_visit && errors.scheduled_visit)}
                                                helperText={touched.scheduled_visit && errors.scheduled_visit}
                                            >
                                                {surveyQuesIO.map((_: any, index: any) => (
                                                    <MenuItem key={index} value={_._id}>
                                                        {_._}
                                                    </MenuItem>
                                                ))}
                                            </LightTextField>
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <LightTextField
                                                fullWidth
                                                id='escalate_to'
                                                type='text'
                                                label="Escalated to (Team)"
                                                select
                                                size="small"
                                                {...getFieldProps('escalate_to')}
                                                error={Boolean(touched.escalate_to && errors.escalate_to)}
                                                helperText={touched.escalate_to && errors.escalate_to}
                                            >
                                                {escalation.map((_: any, index: any) => (
                                                    <MenuItem key={index} value={_._id}>
                                                        {_._}
                                                    </MenuItem>
                                                ))}
                                            </LightTextField>
                                        </Grid>


                                        

                                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                            <LoadingButton type="submit" loading={isSubmitting} variant="contained">
                                                Submit
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Card>
            <DialogInfo />
        </Box>
    );
};
export default TicketsCreateNew;
