import { Box, styled, Card } from "@mui/material";
import { FC, Fragment, useState } from "react";
import { Outlet } from "react-router-dom";

// styled components
const Wrapper = styled(Box)(({ theme }) => ({
  width: `calc(100% - 80px)`,
  maxWidth: 1200,
  margin: "auto",
  paddingLeft: 80,
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginLeft: 0,
    paddingLeft: "2rem",
    paddingRight: "2rem",
  },
}));

const SMSLayout: FC = ({ children }) => {
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);

  return (
    <Fragment>
      <Wrapper>
        <Card>
          <Box sx={{
            height: 100, width: "100%", overflow: "hidden", boxShadow: 1,
            backgroundColor: 'primary.dark',
            '&:hover': {
              backgroundColor: 'primary.main',
              opacity: [0.9, 0.8, 0.7],
            }
          }}>
            <img
              src="/static/background/cover-pic.png"
              alt="Cover"
              height="100%"
              width="100%"
              style={{ objectFit: "cover" }}
            />
          </Box>
        </Card>
        {children || <Outlet />}
      </Wrapper>
    </Fragment>
  );
};

export default SMSLayout;
