import { Box, Grid, Card } from "@mui/material";
import LightIconButton from "components/LightIconButton";
import useTitle from "hooks/useTitle";
import ScanIcon from "icons/ScanIcon";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import QrCodeScanner from "components/QrCodeScanner";
// import CanvasDraw from "react-canvas-draw";
import toast from "react-hot-toast";

const InventoryIndex: FC = () => {
  // change navbar title
  useTitle("Inventory");

  const onNewScanResult = (decodedText: any, decodedResult: any) => {
    // handle decoded results here
    toast.success(decodedText);
  };

  return (
    <Box pt={2} pb={4}>
      <Grid lg={4} md={5} xs={12}>
        {/* <TicketAnalytics /> */}
      </Grid>
      <Grid lg={4} md={5} xs={12}>
        <Card
          sx={{
            display: "flex",
            justifyContent: "space-around",
            padding: 4,
            height: "100%",
            paddingBottom: "1.5rem",
            paddingTop: "calc(1.5rem + 15px)",
          }}
        >
          {/* <LightIconButton onClick={() => { }} size="large">
            <ScanIcon fontSize="small" />
            Scan
          </LightIconButton> */}
          <QrCodeScanner
            fps={10}
            qrbox={250}
            disableFlip={false}
            qrCodeSuccessCallback={onNewScanResult}
          />
          {/* <CanvasDraw
            brushRadius={2}
            hideGrid={true}
          /> */}
        </Card>
      </Grid>
    </Box>
  );
};
export default InventoryIndex;

