import axiosInstance from "utils/axios";
import { accessToken } from "utils/state";

// ----------------------------------------------------------------------

export const accountData = async (req: any) => {
    try {
        const response = await axiosInstance.get(req.url, {
            params: req.params,
            headers: {
                'Authorization': `Token ${accessToken()}`
            }
        });
        return response;
    } catch (error) {
        return {
            status: 401,
            error
        }
    }
};
