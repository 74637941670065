import { SvgIcon, SvgIconProps } from "@mui/material";

const CaptureIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 21 21"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18.5 7.5V4.494a2 2 0 00-1.994-2L13.5 2.485m5 11.015v3a2 2 0 01-2 2h-3m-6-16.015l-3.006.01a2 2 0 00-1.994 2V7.5m5 11h-3a2 2 0 01-2-2v-3"
      />
    </SvgIcon>
  );
};

export default CaptureIcon;
