import {
  Box,
  Card,
  Grid,
  MenuItem,
  Checkbox,
  InputLabel, ListItemText,
} from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import useTitle from "hooks/useTitle";
import { FC, useState, useEffect } from "react";
import * as Yup from "yup";
import LightSelectField from "components/LightSelectField";
import LightTextField from "components/LightTextField";
import { useFormik } from "formik";
import LightButton from "components/LightButton";
import { loggedInUser } from "utils/state";
import toast from "react-hot-toast";
import { saveSuvey } from "redux/customers";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { saveForm } from "redux/forms";
import useIsMountedRef from "hooks/useIsMountedRef";

const SmsSend: FC = () => {
  const user = loggedInUser();
  const navigate = useNavigate();

  // change navbar title
  useTitle(`Send SMS`);

  const isMountedRef = useIsMountedRef();

  const Formchema = Yup.object().shape({
    msisdn: Yup.number().required('Phone no is required'),
    message: Yup.string().required('Composition is required')
  });

  const formik = useFormik({
    initialValues: {
      msisdn: '',
      message: ''
    },
    validationSchema: Formchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      setSubmitting(true);      
      try {
        const payload = {
          ...values
        };
        const form = {
          url: '/v1/sms/sms/send/',
          payload
        };
        saveForm(form).then((response: any) => {
          if (response.status === 200) {
            toast.success('Sms sent');
            resetForm();
            navigate(`/dashboard/sms`);
          }
          setSubmitting(false);

        }).catch((error: any) => {
          setSubmitting(false);
        })

      } catch (error) {
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      }
    }
  });

  const [isSubmitting, setSubmitting] = useState(false);

  const {
    errors, touched, values, initialValues,
    handleSubmit, getFieldProps
  } = formik;

  useEffect(() => {

  }, [values]);


  return (

    <Box pt={1} pb={1}>
      <Card sx={{ padding: 1 }}>
        <Grid container spacing={1}>
          <Grid item md={8} xs={12} lg={8}>
            <Card sx={{ padding: 2, boxShadow: 2 }}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="email"
                      size="small"
                      name="agent"
                      value={user?.email}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="tel"
                      size="small"
                      name="msisdn"
                      label="Phone number"
                      {...getFieldProps('msisdn')}
                      error={Boolean(touched.msisdn && errors.msisdn)}
                      helperText={touched.msisdn && errors.msisdn}
                    />
                  </Grid>


                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      multiline
                      fullWidth
                      size="small"
                      rows={4}
                      name="message"
                      label="Composition"
                      {...getFieldProps('message')}
                      error={Boolean(touched.message && errors.message)}
                      helperText={touched.message && errors.message}
                      sx={{
                        "& .MuiOutlinedInput-root textarea": { padding: 0 },
                      }}
                    >
                    </LightTextField>
                  </Grid>


                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <LoadingButton type="submit" loading={isSubmitting} variant="contained">
                      Send
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
export default SmsSend;
