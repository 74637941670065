import {
  Box,
  Card,
  Grid,
  MenuItem,
  Checkbox,
  InputLabel, ListItemText,
} from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import useTitle from "hooks/useTitle";
import { FC, useState, useEffect } from "react";
import * as Yup from "yup";
import LightSelectField from "components/LightSelectField";
import LightTextField from "components/LightTextField";
import { useFormik } from "formik";
import LightButton from "components/LightButton";
import { loggedInUser } from "utils/state";
import toast from "react-hot-toast";
import { saveSuvey } from "redux/customers";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const FormsAftercareInitialSurvey: FC = () => {
  const user = loggedInUser();
  const navigate = useNavigate();

  // change navbar title
  useTitle(`Pre-aftercare survey`);

  const tf_options: any = [
    { _k: 'yes', _v: "Yes" },
    { _k: 'no', _v: "No" }
  ];
  const [loading, setLoading] = useState(false);

  const initialValues = {
    phone_number: "",
    ticket: "",
    customer_available: "",
    customer_confirmed_name: "",
    county: "",
    nearest_school: "",
    customer_accepted_offer: "",
    next_of_kin_names: "",
    next_of_kin_phone_number: "",
    notes: "",
  };

  const validationSchema = Yup.object().shape({
    phone_number: Yup.string().required(`Phone No is required!`),
    ticket: Yup.number().required(`Ticket is required!`),
    customer_available: Yup.string().required(`Customer availability is required!`),
  });

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      var form = {
        url: 'v1/portfolio_health/pre_aftercare-survey/',
        payload: {
          ...values,
          components
        }
      };
      setLoading(true);
      saveSuvey(form).then((response) => {
        if (response?.status === 201) {
          toast.success('Survey saved');
          navigate('/dashboard/forms/');
        } else {
          toast.error("Something went wrong");
        }
        setLoading(false);
      }).catch((error) => {
        toast.error(error.message);
        setLoading(false);
      });
    },
  });

  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const components_choices = ["No complain/issue", "LSHS Remote", "PS Cells", "PCBA", "PH Battery", "PH Cells", "PHTV Battery", "PHTV Cells", "PMX Cells", "PMX PCBA", "PS Battery", "10W Panel", "30W Panel", "6W Panel", "75W Panel", "80W Panel", "Lamp", "PS Lamp", "Radio", "Torch", "TV Remote", "TV", "Signal"];

  const [components, setComponents] = useState<string[]>([]);

  const handleComponetSelect = (event: any) => {
    const {
      target: { value },
    } = event;
    setComponents(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const [customer_available, set_customer_available] = useState(false);
  const [customer_accepted_offer, set_customer_accepted_offer] = useState(false);

  useEffect(() => {
    set_customer_available((values.customer_available === 'yes'));
    set_customer_accepted_offer((values.customer_accepted_offer === 'yes'));
  }, [values]);
  
  const counties:any = ["Narok", "Kisumu", "Kericho", "Nandi", "Siaya", "Homa Bay", "Elgeyo Marakwet", "Baringo", "Bomet", "Uasin Gishu", "Kakamega", "Nakuru"];

  return (

    <Box pt={1} pb={1}>
      <Card sx={{ padding: 1 }}>
        <Grid container spacing={1}>
          <Grid item md={8} xs={12} lg={8}>
            <Card sx={{ padding: 2, boxShadow: 2 }}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="email"
                      size="small"
                      name="agent"
                      value={user?.email}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="tel"
                      size="small"
                      name="phone_number"
                      label="Customer phone number"
                      value={values.phone_number}
                      onChange={handleChange}
                      error={Boolean(touched.phone_number && errors.phone_number)}
                      helperText={touched.phone_number && errors.phone_number}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="number"
                      size="small"
                      name="ticket"
                      label="Ticket No"
                      value={values.ticket}
                      onChange={handleChange}
                      error={Boolean(touched.ticket && errors.ticket)}
                      helperText={touched.ticket && errors.ticket}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>

                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="customer_available"
                      select
                      label="Is customer available on phone?"
                      value={values.customer_available}
                      onChange={handleChange}
                      error={Boolean(touched.customer_available && errors.customer_available)}
                      helperText={touched.customer_available && errors.customer_available}
                    >
                      {(tf_options).map((option: any, index: any) => (
                        <MenuItem key={index} value={option._k}>
                          {option._v}
                        </MenuItem>
                      ))}
                    </LightTextField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="customer_confirmed_name"
                      select
                      label="Customer name confirmation. (Mention name to customer)"
                      value={values.customer_confirmed_name}
                      onChange={handleChange}
                      error={Boolean(touched.customer_confirmed_name && errors.customer_confirmed_name)}
                      helperText={touched.customer_confirmed_name && errors.customer_confirmed_name}
                      disabled={!customer_available}
                    >
                      {(tf_options).map((option: any, index: any) => (
                        <MenuItem key={index} value={option._k}>
                          {option._v}
                        </MenuItem>
                      ))}
                    </LightTextField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="county"
                      select
                      label="Which county are you based?"
                      value={values.county}
                      onChange={handleChange}
                      error={Boolean(touched.county && errors.county)}
                      helperText={touched.county && errors.county}
                      disabled={!customer_available}
                    >
                      {(counties).map((option: any, index: any) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </LightTextField>

                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="nearest_school"
                      label="Name of nearest school"
                      value={values.nearest_school}
                      onChange={handleChange}
                      error={Boolean(touched.nearest_school && errors.nearest_school)}
                      helperText={touched.nearest_school && errors.nearest_school}
                      disabled={!customer_available}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <InputLabel id="demo-multiple-checkbox-label" sx={{ color: "gray", padding: 1 }}>Product(s) with issues</InputLabel>
                    <LightSelectField
                      fullWidth
                      multiple
                      size="small"
                      name="components_with_issues"
                      value={components}
                      onChange={handleComponetSelect}
                      disabled={!customer_available}
                      renderValue={(selected: any) => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {components_choices.map((_) => (
                        <MenuItem key={_} value={_}>
                          <Checkbox checked={components.indexOf(_) > -1} />
                          <ListItemText primary={_} />
                        </MenuItem>
                      ))}
                    </LightSelectField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="tel"
                      size="small"
                      name="customer_accepted_offer"
                      select
                      label="Has customer accepted the offer?"
                      value={values.customer_accepted_offer}
                      onChange={handleChange}
                      error={Boolean(touched.customer_accepted_offer && errors.customer_accepted_offer)}
                      helperText={touched.customer_accepted_offer && errors.customer_accepted_offer}
                      disabled={!customer_available}
                    >
                      {(tf_options).map((option: any, index: any) => (
                        <MenuItem key={index} value={option._k}>
                          {option._v}
                        </MenuItem>
                      ))}
                    </LightTextField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="next_of_kin_names"
                      label="Next of kin Names"
                      value={values.next_of_kin_names}
                      onChange={handleChange}
                      error={Boolean(touched.next_of_kin_names && errors.next_of_kin_names)}
                      helperText={touched.next_of_kin_names && errors.next_of_kin_names}
                      disabled={!customer_accepted_offer}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="tel"
                      size="small"
                      name="next_of_kin_phone_number"
                      label="Next of kin Phone No"
                      value={values.next_of_kin_phone_number}
                      onChange={handleChange}
                      error={Boolean(touched.next_of_kin_phone_number && errors.next_of_kin_phone_number)}
                      helperText={touched.next_of_kin_phone_number && errors.next_of_kin_phone_number}
                      disabled={!customer_accepted_offer}
                    />
                  </Grid>


                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      multiline
                      fullWidth
                      size="small"
                      rows={5}
                      name="notes"
                      label="Notes"
                      value={values.notes}
                      onChange={handleChange}
                      error={Boolean(touched.notes && errors.notes)}
                      helperText={touched.notes && errors.notes}
                      sx={{
                        "& .MuiOutlinedInput-root textarea": { padding: 0 },
                      }}
                    >
                    </LightTextField>
                  </Grid>


                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    {loading ? (
                      <LoadingButton loading fullWidth variant="contained">
                        Submit
                      </LoadingButton>
                    ) : (
                      <LightButton fullWidth type="submit" variant="contained">
                        Submit
                      </LightButton>
                    )}
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
export default FormsAftercareInitialSurvey;
