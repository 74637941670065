import { CssBaseline, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { FC, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import { ukoTheme } from "./theme";
import { getCurrentPosition, setLoginPosition } from "utils/geoPosition";
import routes from "routes";

const App: FC = () => {
  const allowedPages = useRoutes(routes);
  // App theme
  const appTheme = ukoTheme();

  // toaster options
  const toasterOptions = {
    style: {
      fontWeight: 500,
      fontFamily: "'Montserrat', sans-serif",
    },
  };

  const [geoFound, setGeoFound] = useState(false);

  useEffect(() => {
    getCurrentPosition().then((coords: any) => {
      setLoginPosition(coords)
      setGeoFound(true);
    }).catch((error) => {
      console.log(error);
    });
  }, [geoFound]);


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <Toaster toastOptions={toasterOptions} />
        {allowedPages}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
