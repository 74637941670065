import { AuthProvider } from "contexts/JWTAuthContext";
import SettingsProvider from "contexts/SettingsContext";
import TitleContextProvider from "contexts/TitleContext";
import LangContextProvider from "contexts/LangContext";
import "nprogress/nprogress.css";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import "react-image-lightbox/style.css";
import { BrowserRouter } from "react-router-dom";
import "simplebar/dist/simplebar.min.css";
import App from "./App";
import "./__fakeApi__";

ReactDOM.render(
  <StrictMode>
    <AuthProvider>
      <SettingsProvider>
        <LangContextProvider>
          <TitleContextProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </TitleContextProvider>
        </LangContextProvider>
      </SettingsProvider>
    </AuthProvider>
  </StrictMode>,
  document.getElementById("root")
);
