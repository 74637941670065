import FlexBox from "components/FlexBox";
import { H6, Small, Tiny } from "components/Typography";
import moment from "moment";

const ColumnShape = [
  {
    Header: "Phone No",
    accessor: "phone_number",
    minWidth: 200,
    Cell: ({ row }: any) => {
      const { phone_number, ticket } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column" ml={1}>
            <H6 color="text.primary">Phone: {phone_number}</H6>
            <Tiny color="text.disabled">Ticket: {ticket}</Tiny>
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Available",
    accessor: "customer_available",
    minWidth: 150,
  },
  {
    Header: "Confirmed name",
    accessor: "customer_confirmed_name",
    minWidth: 150,
  },{
    Header: "Location",
    accessor: "county",
    minWidth: 200,
    Cell: ({ row }: any) => {
      const { county, nearest_school } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column" ml={1}>
            <H6 color="text.primary">{county}</H6>
            <Tiny color="text.disabled">{nearest_school}</Tiny>
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Accepted offer",
    accessor: "customer_accepted_offer",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    Header: "Next of kin",
    accessor: "next_of_kin_names",
    minWidth: 200,
    Cell: ({ row }: any) => {
      const { next_of_kin_names, next_of_kin_phone_number } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column" ml={1}>
            <H6 color="text.primary">{next_of_kin_names}</H6>
            <Tiny color="text.disabled">{next_of_kin_phone_number}</Tiny>
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Date",
    accessor: "date_created",
    minWidth: 140,
    Cell: ({ value }: any) => (
      <>
        {(value !== undefined) ? moment(new Date(value).toLocaleString()).format("YYYY-MM-DD HH:mm:ss") : 'None'}
      </>
    ),
  },
];

export default ColumnShape;
