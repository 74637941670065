import FlexBox from "components/FlexBox";
import { H6, Small, Tiny } from "components/Typography";
import moment from "moment";

const ColumnShape = [
  {
    Header: "Phone No",
    accessor: "phone_number",
    minWidth: 200,
    Cell: ({ row }: any) => {
      const { ticket } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column" ml={1}>
            <H6 color="text.primary">Ticket</H6>
            <Tiny color="text.disabled">{ticket}</Tiny>
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Date",
    accessor: "_date_added",
    minWidth: 140,
    Cell: ({ value }: any) => (
      <>
        {(value !== undefined) ? moment(new Date(value).toLocaleString()).format("YYYY-MM-DD") : 'None'}
      </>
    ),
  },
];

export default ColumnShape;
