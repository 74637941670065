import {
  Box,
  Card,
  Grid,
  MenuItem,
  Checkbox,
  InputLabel, ListItemText, CardContent, List, ListItem,
} from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import useTitle from "hooks/useTitle";
import { FC, useState, useEffect } from "react";
import * as Yup from "yup";
import LightSelectField from "components/LightSelectField";
import LightTextField from "components/LightTextField";
import { useFormik } from "formik";
import LightButton from "components/LightButton";
import { loggedInUser } from "utils/state";
import toast from "react-hot-toast";
import { saveSuvey } from "redux/customers";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { H4, Small } from "components/Typography";

const FormsD100CRPSurvey: FC = () => {
  const user = loggedInUser();
  const navigate = useNavigate();

  // change navbar title
  useTitle(`Customer verification process survey`);

  const tf_options: any = [
    { _k: 'yes', _v: "Yes" },
    { _k: 'no', _v: "No" }
  ];
  const [loading, setLoading] = useState(false);

  const initialValues: any = {
    account_number: "",
    customer_available: "",
    product_beneficiary_exists: null,
    who_is_using_product: null,
    product_in_household: null,
    where_is_product_installed: null,
    product_working: null,
    faulty_indication: null,
    notes: "",
  };

  const validationSchema = Yup.object().shape({
    account_number: Yup.string().required(`Account No is required!`),
    customer_available: Yup.string().required(`Customer availability is required!`),
  });

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      var form = {
        url: 'v1/pwa/d100cvpsurvey/',
        payload: {
          ...values,
        }
      };
      setLoading(true);
      saveSuvey(form).then((response) => {
        if (response?.status === 201) {
          toast.success('Survey saved');
          navigate('/dashboard/forms/');
        } else {
          toast.error("Something went wrong");
        }
        setLoading(false);
      }).catch((error) => {
        toast.error(error.message);
        setLoading(false);
      });
    },
  });

  const [customer_available, set_customer_available] = useState(false);
  const [product_beneficiary_exists_io, set_product_beneficiary_exists] = useState(false);
  const [product_in_household_io, set_product_in_household] = useState(false);
  const [product_working_io, set_product_working] = useState(false);

  useEffect(() => {
    set_customer_available((values.customer_available === 'yes'));
    set_product_beneficiary_exists((values.product_beneficiary_exists === 'yes'));
    set_product_in_household((values.product_in_household === 'yes'));
    set_product_working((values.product_working === 'yes'));
  }, [values]);



  return (

    <Box pt={1} pb={1}>
      <Card sx={{ padding: 1 }}>
        <Grid container spacing={1}>
          <Grid item md={8} xs={12} lg={8}>
            <Card sx={{ padding: 2, boxShadow: 2 }}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="email"
                      size="small"
                      name="agent"
                      value={user?.email}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="account_number"
                      label="Account number"
                      value={values.account_number}
                      onChange={handleChange}
                      error={Boolean(touched.account_number && errors.account_number)}
                      helperText={touched.account_number && errors.account_number}
                    />
                  </Grid>


                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="customer_available"
                      select
                      label="Is customer available?"
                      value={values.customer_available}
                      onChange={handleChange}
                      error={Boolean(touched.customer_available && errors.customer_available)}
                      helperText={touched.customer_available && errors.customer_available}
                    >
                      {(tf_options).map((option: any, index: any) => (
                        <MenuItem key={index} value={option._k}>
                          {option._v}
                        </MenuItem>
                      ))}
                    </LightTextField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      select
                      type="text"
                      size="small"
                      name="product_beneficiary_exists"
                      label="Does the product beneficiary exist?"
                      value={values.product_beneficiary_exists}
                      onChange={handleChange}
                      disabled={!customer_available}
                    >
                      {(tf_options).map((option: any, index: any) => (
                        <MenuItem key={index} value={option._k}>
                          {option._v}
                        </MenuItem>
                      ))}
                    </LightTextField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      size="small"
                      name="who_is_using_product"
                      label="Who is using the product"
                      value={values.who_is_using_product}
                      onChange={handleChange}
                      disabled={product_beneficiary_exists_io}
                      sx={{
                        "& .MuiOutlinedInput-root textarea": { padding: 0 },
                      }}
                    >
                    </LightTextField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      select
                      type="text"
                      size="small"
                      name="product_in_household"
                      label="Is the product in the house hold?"
                      value={values.product_in_household}
                      onChange={handleChange}
                      disabled={!customer_available}
                    >
                      {(tf_options).map((option: any, index: any) => (
                        <MenuItem key={index} value={option._k}>
                          {option._v}
                        </MenuItem>
                      ))}
                    </LightTextField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      size="small"
                      name="where_is_product_installed"
                      label="Where is the product installed?"
                      value={values.where_is_product_installed}
                      onChange={handleChange}
                      disabled={product_in_household_io}
                      sx={{
                        "& .MuiOutlinedInput-root textarea": { padding: 0 },
                      }}
                    >
                    </LightTextField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      select
                      type="text"
                      size="small"
                      name="product_working"
                      label="Is the product working?"
                      value={values.product_working}
                      onChange={handleChange}
                      disabled={!customer_available}
                    >
                      {(tf_options).map((option: any, index: any) => (
                        <MenuItem key={index} value={option._k}>
                          {option._v}
                        </MenuItem>
                      ))}
                    </LightTextField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      multiline
                      fullWidth
                      rows={3}
                      size="small"
                      name="faulty_indication"
                      label="Fault indication"
                      value={values.faulty_indication}
                      onChange={handleChange}
                      disabled={product_working_io}
                      sx={{
                        "& .MuiOutlinedInput-root textarea": { padding: 0 },
                      }}
                    >
                    </LightTextField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      multiline
                      fullWidth
                      size="small"
                      rows={5}
                      name="notes"
                      label="Notes"
                      value={values.notes}
                      onChange={handleChange}
                      error={Boolean(touched.notes && errors.notes)}
                      helperText={touched.notes && errors.notes}
                      sx={{
                        "& .MuiOutlinedInput-root textarea": { padding: 0 },
                      }}
                    >
                    </LightTextField>
                  </Grid>


                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    {loading ? (
                      <LoadingButton loading fullWidth variant="contained">
                        Submit
                      </LoadingButton>
                    ) : (
                      <LightButton fullWidth type="submit" variant="contained">
                        Submit
                      </LightButton>
                    )}
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Grid>
          <Grid item md={4} xs={12} lg={4}>
            <Card>
              <CardContent>
                <H4 mt={2}>{`INSTRUCTIONS`}</H4>
                <Small color="text.disabled">
                  {`Inform the Customer of the following`}
                  <List >
                    <ListItem>Toll-Free number and working hours</ListItem>
                    <ListItem>Someone will come to visit or call the customer to confirm details about the SHS and account no. will be asked</ListItem>
                    <ListItem>When a visit or call is made, they should be available</ListItem>
                  </List>
                </Small>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
export default FormsD100CRPSurvey;
