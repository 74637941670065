import { createContext, ReactNode, useState } from "react";

export const LangContext = createContext({
  lang: "",
  setLang: (arg: string) => {},
});

// props types for provider
type ProviderProps = {
  children: ReactNode;
};

const LangContextProvider = ({ children }: ProviderProps) => {
  const [lang, setLang] = useState("");

  return (
    <LangContext.Provider value={{ lang, setLang }}>
      {children}
    </LangContext.Provider>
  );
};

export default LangContextProvider;
