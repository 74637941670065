import { useEffect, useMemo, useState } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
    MRT_ColumnDef,
    MRT_ColumnFiltersState,
    MRT_PaginationState,
    MRT_SortingState,
} from 'material-react-table';

import axiosInstance from 'utils/axios';
import { accessToken, loggedInUser } from 'utils/state';
import { useTheme } from '@emotion/react';
import useTitle from 'hooks/useTitle';
import { useSearchParams } from 'react-router-dom';
import FlexBox from 'components/FlexBox';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { updateTickets } from 'redux/tickets';
import toast from 'react-hot-toast';

type TicketsApiResponse = {
    results: Array<any>;
    count: number;
    next: string;
    previous: string;
};

type Ticket = {
    id: string;
    priority: string;
    date: Date;
    title: string;
    description: string;
    tags: Array<string>;
    status: string;
    escalate_to: string;
    call_in_phone_number: string;
    created_by?: number;
};

const TicketList = () => {

    const theme = useTheme();

    const user = loggedInUser();
    const [searchParams, setSearchParams] = useSearchParams();

    let orgId = searchParams.get("orgId");
    const orgName = atob(orgId);
    useTitle(`Ticket list (${orgName})`);

    const [editedTickets, setEditedTickets] = useState<Record<string, Ticket>>({});
    const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});

    //data and fetching state
    const [data, setData] = useState<Ticket[]>([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //table state
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([],);
    const [globalFilter, setGlobalFilter] = useState('');
    // const [sorting, setSorting] = useState<MRT_SortingState>([]);

    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 15,
    });

    //if you want to avoid useEffect, look at the React Query example instead
    useEffect(() => {
        const fetchData = async () => {
            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL(
                'v1/portfolio_health/associated-account-ticket/',
                process.env.REACT_APP_API_ROOT,
            );
            url.searchParams.set(
                'offset',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('limit', `${pagination.pageSize}`);

            columnFilters.map((_: any) => {
                if (['id', 'status', 'call_in_phone_number'].includes(_.id)) {
                    console.log(typeof (_.value));

                    url.searchParams.set(_.id, (typeof (_.value) == "object") ? JSON.stringify(_.value) : _.value);
                }
            });
            //   url.searchParams.set('globalFilter', globalFilter ?? '');

            url.searchParams.set('origin_account_type', (orgName == 'mwezi') ? 'mwezi' : 'solrm');
            // url.searchParams.set('sorting', JSON.stringify(sorting ?? []));

            try {
                // const response = await fetch(url.href);
                const response = await axiosInstance.get(url.href, {
                    headers: {
                        'Authorization': `Token ${accessToken()}`
                    }
                });
                const json = (await response.data) as TicketsApiResponse;

                setData(json.results);
                setRowCount(json.count);
            } catch (error) {
                setIsError(true);
                console.error(error);
                return;
            }
            setIsError(false);
            setIsLoading(false);
            setIsRefetching(false);
        };
        fetchData();
    }, [
        columnFilters, //re-fetch when column filters change
        // globalFilter, //re-fetch when global filter changes
        pagination.pageIndex, //re-fetch when page index changes
        pagination.pageSize, //re-fetch when page size changes
        // sorting, //re-fetch when sorting changes
    ]);

    const columns = useMemo<MRT_ColumnDef<Ticket>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'Ticket #',
                filterVariant: 'text',
            },
            {
                accessorKey: 'call_in_phone_number',
                header: 'Calling No',
                filterVariant: 'text',
            },
            {
                accessorKey: 'title',
                header: 'Title',
            },
            {
                accessorKey: 'priority',
                header: 'Priority',
            },
            {
                accessorKey: 'escalate_to',
                header: 'Escalate',
                filterVariant: 'multi-select',
                filterSelectOptions: ['technical', 'inventory', 'field'],
                editVariant: 'select',
                editSelectOptions: ['technical', 'inventory', 'field'],
                muiEditTextFieldProps: ({ row }) => ({
                    select: true,
                    error: !!validationErrors?.escalate_to,
                    helperText: validationErrors?.escalate_to,
                    onChange: (event) =>
                        setEditedTickets({
                            ...editedTickets,
                            [row.id]: { ...row.original, escalate_to: event.target.value },
                        }),
                }),
            },
            {
                accessorKey: 'date',
                header: 'Date',
                // filterVariant: 'date-range',
                // Cell: ({ cell }) => cell.getValue<Date>().toLocaleDateString(), // convert back to string for display
            },
            {
                accessorKey: 'description',
                header: 'Description',
            },
            {
                accessorKey: 'status',
                header: 'Status',
                filterVariant: 'multi-select',
                filterSelectOptions: ['open', 'closed'],
                editVariant: 'select',
                editSelectOptions: ['open', 'closed'],
                muiEditTextFieldProps: ({ row }) => ({
                    select: true,
                    error: !!validationErrors?.status,
                    helperText: validationErrors?.status,
                    onChange: (event) =>
                        setEditedTickets({
                            ...editedTickets,
                            [row.id]: { ...row.original, status: event.target.value },
                        }),
                }),
            }

        ],
        [editedTickets, validationErrors],
    );

    // const { mutateAsync: updateTickets, isPending: isUpdatingTickets } = useUpdateTickets();
    const [isUpdatingTickets, setIsUpdatingTickets] = useState<boolean>(false);
    //UPDATE action
    const handleSaveTickets = async () => {
        if (Object.values(validationErrors).some((error) => !!error)) return;
        // await updateTickets(Object.values(editedTickets));
        // console.log(editedTickets);
        var ticket: Ticket;

        Object.keys(editedTickets || []).map((_: any)=> {
            ticket = editedTickets[_];
        });
        
        setIsUpdatingTickets(true);
        updateTickets({
            'url': `/v1/portfolio_health/associated-account-ticket/${ticket.id}/`,
            'payload': {
                created_by: ticket.created_by,
                title: ticket.title,
                escalate_to:ticket.escalate_to,
                status: ticket.status
            }
        }).then((response: any) => {
            if (response.status === 201) {
                toast.success('Saved');
            }
            setIsUpdatingTickets(false);
        }).catch((error: any) => {
            setIsUpdatingTickets(false);
        })
        setEditedTickets({});
    };

    const table = useMaterialReactTable({
        columns,
        data,
        columnFilterDisplayMode: 'popover',
        editDisplayMode: 'cell',
        enableRowSelection: false,
        enableEditing: true,
        positionActionsColumn: 'last',
        getRowId: (row) => row.id,
        initialState: { showColumnFilters: false },
        manualFiltering: false,
        manualPagination: true,
        manualSorting: false,
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading',
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        // onSortingChange: setSorting,
        rowCount,
        renderBottomToolbarCustomActions: () => (
            <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                <Button
                    color="success"
                    variant="contained"
                    onClick={handleSaveTickets}
                    disabled={
                        Object.keys(editedTickets).length === 0 ||
                        Object.values(validationErrors).some((error) => !!error)
                    }
                >
                    {isUpdatingTickets ? <CircularProgress size={25} /> : 'Save'}
                </Button>
                {Object.values(validationErrors).some((error) => !!error) && (
                    <Typography color="error">Fix errors before submitting</Typography>
                )}
            </Box>
        ),
        state: {
            columnFilters,
            // globalFilter,
            isLoading,
            pagination,
            isSaving: isUpdatingTickets,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            // sorting,
        },
    });


    return <FlexBox sx={{
        padding: 0
    }}>
        <MaterialReactTable table={table} />
    </FlexBox>;
};

// //UPDATE hook (put user in api)
// function useUpdateTickets() {
//     const queryClient = useQueryClient();
//     return useMutation({
//         mutationFn: async (rows: Ticket[]) => {
//             //send api update request here
//             await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
//             return Promise.resolve();
//         },
//         //client side optimistic update
//         onMutate: (newTickets: Ticket[]) => {
//             queryClient.setQueryData(
//                 ['rows'],
//                 (prevTickets: any) =>
//                     prevTickets?.map((ticket: Ticket) => {
//                         const newTicket = newTickets.find((u) => u.id === ticket.id);
//                         return newTicket ? newTicket : ticket;
//                     }),
//             );
//         },
//     });
// }

export default TicketList;

