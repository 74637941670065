import { SvgIcon, SvgIconProps } from "@mui/material";

const ShareIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 23 21" {...props}>
      <path
        d="M0.956932 21C0.886741 21 0.816317 20.9921 0.746126 20.9735C0.31305 20.8699 0 20.4889 0 20.0319V18.0616C0 11 5.58904 5.25457 12.4584 5.25457H12.7777V0.985399C12.7777 0.583495 13.0154 0.222239 13.3783 0.0711946C13.7398 -0.0784068 14.1551 0.0120274 14.426 0.302331L22.7317 9.16874C23.0894 9.54972 23.0894 10.1539 22.7317 10.5349L14.426 19.4013C14.1551 19.6916 13.7372 19.7811 13.3783 19.6324C13.0154 19.4814 12.7777 19.1201 12.7777 18.7182V14.4493H11.2597C7.23993 14.4493 3.62745 16.7453 1.83104 20.4403C1.66609 20.7819 1.31982 21 0.956932 21ZM12.4584 7.22488C7.09159 7.22488 2.64759 11.369 1.99716 16.7085C4.32 14.0447 7.65124 12.479 11.2597 12.479H13.7361C14.2651 12.479 14.6944 12.9203 14.6944 13.4641V16.275L20.7114 9.85181L14.6944 3.42857V6.23973C14.6944 6.78354 14.2651 7.22488 13.7361 7.22488H12.4584Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default ShareIcon;
