// import axiosInstance from "utils/axios";
import axios from "axios";

// ----------------------------------------------------------------------

export const uploadImage = async (form: any) => {
    try {
        const response = await axios.post(form.url, form.payload, {
            headers: {
            }
        });
        return response;
    } catch (error) {
        return {
            status: 401,
            error
        };
    }
};