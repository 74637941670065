import {
  Box,
  Card,
  Grid,
  MenuItem,
} from "@mui/material";
import useAuth from "hooks/useAuth";
import useTitle from "hooks/useTitle";
import { FC } from "react";
import * as Yup from "yup";
import LightTextField from "components/LightTextField";
import { useFormik } from "formik";
import { TRANSLATIONS } from "../../../constants";
import LightButton from "components/LightButton";

const FormsFOCollectionSurvey: FC = () => {
  const { user } = useAuth();

  console.log(user);
  
  var lang = user?.lang;

  const field_required_trans = TRANSLATIONS.FORMS.GLOBAL.REQUIRED[lang];

  const tf_options: any = [
    { _k: 'yes', _v: TRANSLATIONS.FORMS.GLOBAL.YES[lang] },
    { _k: 'no', _v: TRANSLATIONS.FORMS.GLOBAL.NO[lang] }
  ];
  // change navbar title
  useTitle(`${TRANSLATIONS.FORMS.FOPS_CS.TITLE[user?.lang]}`);
  // const { lang } = useContext(LangContext);

  const initialValues = {
    phone_number: "",
    customer_available: "",
    reason_fo_unavailability: "",
    customer_accepted_to_pay: "",
    reason_for_not_paying: "",
    explanation_for_not_paying: "",
    date_promised_to_pay: "",
  };

  const validationSchema = Yup.object().shape({
    phone_number: Yup.string().required(`${TRANSLATIONS.FORMS.FOPS_CS.FIELDS["phone_number"][lang]} ${field_required_trans}`),
    customer_available: Yup.string().required(`${TRANSLATIONS.FORMS.FOPS_CS.FIELDS["customer_available"][lang]} ${field_required_trans}`),
  });

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => { },
  });

  return (
    <Box pt={1} pb={1}>
      <Card sx={{ padding: 1 }}>
        <Grid container spacing={1}>
          <Grid item md={8} xs={12} lg={8}>
            <Card sx={{ padding: 2, boxShadow: 2 }}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="text"
                      size="small"
                      name="agent"
                      label={TRANSLATIONS.GLOBAL.LOGGED_IN[lang]}
                      value={user?.email}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="tel"
                      size="small"
                      name="phone_number"
                      label={TRANSLATIONS.FORMS.FOPS_CS.FIELDS["phone_number"][lang]}
                      value={values.phone_number}
                      onChange={handleChange}
                      error={Boolean(touched.phone_number && errors.phone_number)}
                      helperText={touched.phone_number && errors.phone_number}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="tel"
                      size="small"
                      name="customer_available"
                      select
                      label={TRANSLATIONS.FORMS.FOPS_CS.FIELDS["customer_available"][lang]}
                      value={values.customer_available}
                      onChange={handleChange}
                      error={Boolean(touched.customer_available && errors.customer_available)}
                      helperText={touched.customer_available && errors.customer_available}
                    >
                      {(tf_options).map((option: any, index: any) => (
                        <MenuItem key={index} value={option._k}>
                          {option._v}
                        </MenuItem>
                      ))}
                    </LightTextField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="tel"
                      size="small"
                      name="reason_fo_unavailability"
                      select
                      label={TRANSLATIONS.FORMS.FOPS_CS.FIELDS["reason_fo_unavailability"][lang]}
                      value={values.reason_fo_unavailability}
                      onChange={handleChange}
                      error={Boolean(touched.reason_fo_unavailability && errors.reason_fo_unavailability)}
                      helperText={touched.reason_fo_unavailability && errors.reason_fo_unavailability}
                    >
                      {(TRANSLATIONS.FORMS.FOPS_CS.FIELDS["reason_fo_unavailability"].OPTIONS).map((option: any, index: any) => (
                        <MenuItem key={index} value={option[lang]}>
                          {option[lang]}
                        </MenuItem>
                      ))}
                    </LightTextField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="tel"
                      size="small"
                      name="customer_accepted_to_pay"
                      select
                      label={TRANSLATIONS.FORMS.FOPS_CS.FIELDS["customer_accepted_to_pay"][lang]}
                      value={values.customer_accepted_to_pay}
                      onChange={handleChange}
                      error={Boolean(touched.customer_accepted_to_pay && errors.customer_accepted_to_pay)}
                      helperText={touched.customer_accepted_to_pay && errors.customer_accepted_to_pay}
                    >
                      {(tf_options).map((option: any, index: any) => (
                        <MenuItem key={index} value={option._k}>
                          {option._v}
                        </MenuItem>
                      ))}
                    </LightTextField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="tel"
                      size="small"
                      name="reason_for_not_paying"
                      select
                      label={TRANSLATIONS.FORMS.FOPS_CS.FIELDS["reason_for_not_paying"][lang]}
                      value={values.reason_for_not_paying}
                      onChange={handleChange}
                      error={Boolean(touched.reason_for_not_paying && errors.reason_for_not_paying)}
                      helperText={touched.reason_for_not_paying && errors.reason_for_not_paying}
                    >
                      {(TRANSLATIONS.FORMS.FOPS_CS.FIELDS["reason_for_not_paying"].OPTIONS).map((option: any, index: any) => (
                        <MenuItem key={index} value={option[lang]}>
                          {option[lang]}
                        </MenuItem>
                      ))}
                    </LightTextField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      multiline
                      fullWidth
                      size="small"
                      rows={5}
                      name="explanation_for_not_paying"
                      label={TRANSLATIONS.FORMS.FOPS_CS.FIELDS["explanation_for_not_paying"][lang]}
                      value={values.explanation_for_not_paying}
                      onChange={handleChange}
                      error={Boolean(touched.explanation_for_not_paying && errors.explanation_for_not_paying)}
                      helperText={touched.explanation_for_not_paying && errors.explanation_for_not_paying}
                      sx={{
                        "& .MuiOutlinedInput-root textarea": { padding: 0 },
                      }}
                    >
                    </LightTextField>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LightTextField
                      fullWidth
                      type="date"
                      size="small"
                      name="date_promised_to_pay"
                      label={TRANSLATIONS.FORMS.FOPS_CS.FIELDS["date_promised_to_pay"][lang]}
                      value={values.date_promised_to_pay}
                      onChange={handleChange}
                      error={Boolean(touched.date_promised_to_pay && errors.date_promised_to_pay)}
                      helperText={touched.date_promised_to_pay && errors.date_promised_to_pay}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LightButton type="submit" variant="contained" size="small">
                      Submit
                    </LightButton>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
export default FormsFOCollectionSurvey;
