import { SvgIcon, SvgIconProps } from "@mui/material";

const PlusIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 512 512"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={32}
        d="M256 112v288M400 256H112"
      />
    </SvgIcon>
  );
};

export default PlusIcon;
