import axiosInstance from "utils/axios";
import { accessToken } from "utils/state";

// ----------------------------------------------------------------------

export const saveTicket = async (form: any) => {
    try {

        const response = await axiosInstance.post(form.url, form.payload, {
            headers: {
                'Authorization': `Token ${accessToken()}`
            }
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};


export const updateTickets = async (form: any) => {
    try {

        const response = await axiosInstance.put(form.url, form.payload, {
            headers: {
                'Authorization': `Token ${accessToken()}`
            }
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const listTickets = async (req: any) => {
    try {
        const response = await axiosInstance.get(req.url, {
            headers: {
                'Authorization': `Token ${accessToken()}`
            }
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};
