import { styled, Select, SelectProps } from "@mui/material";

const StyledSelect = styled(Select)<SelectProps>(({ theme }) => ({
  padding: 2,
  "& .MuiOutlinedInput-input": {
    fontWeight: 40,
    color: theme.palette.text.primary,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
    border: "1px solid",
    borderColor:
      theme.palette.mode === "light"
        ? theme.palette.secondary[300]
        : theme.palette.divider,
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.secondary[300],
  },
}));

const LightSelectField = (props: SelectProps) => {
  return  <StyledSelect {...props} />;
};

export default LightSelectField;
